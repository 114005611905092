import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from './Loading';

const getRadi = (data) => {
  if (Number(data.radioactivityLevel) < 1) {
    return '안전';
  } else if (Number(data.radioactivityLevel) < 100) {
    return '주의/정밀측정 요망';
  } else {
    return '위험';
  }
};
export default function BasicInfo({
  data,
  setPopupOn,
  isGetDataSuccess4,
  setLoadingImagePos,
  setResultPopupData,
}) {
  const history = useHistory();
  const [isStartAnim, setIsStartAnim] = useState(false);

  const getCurDateForm = (curDateTime) => {
    return `
            ${curDateTime.getFullYear()}. 
            ${
              curDateTime.getMonth() + 1 < 10
                ? '0' + (curDateTime.getMonth() + 1)
                : curDateTime.getMonth() + 1
            }. 
            ${curDateTime.getDate() < 10 ? '0' + curDateTime.getDate() : curDateTime.getDate()}. 
            ${
              curDateTime.getHours() < 10 ? '0' + curDateTime.getHours() : curDateTime.getHours()
            } : 
            ${
              curDateTime.getMinutes() < 10
                ? '0' + curDateTime.getMinutes()
                : curDateTime.getMinutes()
            }
        `;
  };

  const getCurDateFormParamString = (curDateTime) => {
    return `
            ${curDateTime.substr(0, 4)}. 
            ${curDateTime.substr(5, 2)}. 
            ${curDateTime.substr(8, 2)}. 
            ${curDateTime.substr(11, 2)} : 
            ${curDateTime.substr(14, 2)}
        `;
  };

  const getfreshnessPerRotateValue = (freshnessPer) => {
    return freshnessPer * 2.55 + 235;
  };

  const getTimeForm = (circulationTotalMinute) => {
    const hours = Math.floor(circulationTotalMinute / 60);
    const minutes = circulationTotalMinute % 60;
    return `${hours}시간 ${minutes}분`;
  };

  useEffect(() => {
    if (data.freshnessPer) {
      setTimeout(() => {
        setIsStartAnim(true);
      }, 300);
    }
  }, [data.freshnessPer]);

  return (
    <section id="basicinfo">
      <section className="standard" style={{ marginTop: 3 }}>{`${getCurDateForm(
        new Date(),
      )} 기준`}</section>
      <h3 style={{ marginTop: 20 }}>{data.name && <>{data.name}</>}</h3>
      <p className="detail">{data.origin && <>{data.origin}</>}</p>
      <p className="decoline" style={{ marginBottom: 0 }}>
        <img src={require('../../images/deco_line.png')} alt="deco_line" />
      </p>
      <p className="fresh" style={{}}>
        이 제품의 물류신선도는
      </p>
      <p className="fresh_graph" style={{ zIndex: 10 }}>
        {data.freshnessPer ? (
          <>
            <span className="graph_data">
              {data.freshnessPer && <FreshnessStandard freshnessPer={data.freshnessPer} />}
            </span>
            <br />
            <span
              onClick={() => {
                if (isGetDataSuccess4) history.push('/result/logistics_freshness_data');
              }}
              style={{
                fontSize: 24,
                color: isGetDataSuccess4 ? '#153d7a' : '#153d7abb',
                borderBottom: '1px solid #153d7a',
                fontFamily: 'Noto Sans KR',
                fontWeight: '500',
                transition: '0.3s',
                zIndex: 20,
                position: 'relative',
              }}>
              물류신선도란?
            </span>
          </>
        ) : (
          <div style={{ position: 'absolute', top: 420, left: 'calc(50% - 40px)' }}>
            <Loading />
          </div>
        )}
      </p>
      <div className="verification">
        <div
          style={{
            transform: `rotate(${
              isStartAnim ? getfreshnessPerRotateValue(data.freshnessPer) : 235
            }deg)`,
          }}
          className="verification_img_area">
          <img
            className="graph_freshness_circle"
            src={require('../../images/graph_freshness_circle.png')}
            alt="graph_freshness_circle"
          />
        </div>
        <div className="check_button">
          <img
            onClick={() => {
              setLoadingImagePos(0);
              setResultPopupData(null);
              setPopupOn('result');
            }}
            src={require('../../images/btn_verification.png')}
            alt="블록체인 데이터 검증"
          />
        </div>
      </div>
      {!data.averageTemperature && (
        <div style={{ bottom: 55, position: 'absolute', top: 835, left: 'calc(50% - 40px)' }}>
          <Loading />
        </div>
      )}
      <div style={{ height: 53 }} className="average clearboth">
        <ul>
          <li className="data_label">
            <span style={{ fontWeight: 400, color: '#191919' }}>
              {data.averageTemperature && '평균온도 :'}
            </span>
            <span className="data">
              {data.averageTemperature && <>{` ${data.averageTemperature}℃`}</>}
            </span>
          </li>
          <li className="data_label">
            <span style={{ fontWeight: 400, color: '#191919' }}>
              {data.averageHumidity && '평균습도 :'}
            </span>
            <span className="data">
              {data.averageHumidity && <>{` ${data.averageHumidity}%`}</>}
            </span>
          </li>
          <li className="data_label">
            <span style={{ fontWeight: 400, color: '#191919' }}>
              {data.averageTemperature && '방사능 :'}
            </span>

            <span className="data">{data.radioactivityLevel && <> {` ${getRadi(data)}`}</>}</span>
          </li>
        </ul>
      </div>
      <div className="distribution_time">
        <div className="distribution_data">
          <p className="time_start" style={{ fontWeight: 'lighter' }}>
            <span style={{ position: 'relative', top: 7 }}>
              {data.startTime && <>{getCurDateFormParamString(data.startTime)}</>}
            </span>
          </p>
          <p className="time_end" style={{ fontWeight: 'lighter' }}>
            <span style={{ position: 'relative', top: 7 }}>
              {data.endTime && <>{getCurDateFormParamString(data.endTime)}</>}
            </span>
          </p>
          <p className="time_total">
            <span style={{ fontWeight: 400, color: '#191919' }}>
              {data.circulationTotalMinute && '총 유통시간'}
            </span>
            <br />
            <span className="time_total_data">
              {data.circulationTotalMinute && <>{getTimeForm(data.circulationTotalMinute)}</>}
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

const FreshnessStandard = ({ freshnessPer }) => {
  const [opacity, setOpacity] = useState(0);

  const getFreshnessStandard = (freshnessPer) => {
    if (freshnessPer >= 95) {
      return '최고신선';
    } else if (freshnessPer >= 90) {
      return '매우신선';
    } else if (freshnessPer >= 80) {
      return '보통신선';
    } else if (freshnessPer >= 70) {
      return '보통';
    } else {
      return '신선기준 이하';
    }
  };

  useEffect(() => {
    if (opacity < 1) {
      setTimeout(() => {
        setOpacity(opacity + 0.1);
      }, (freshnessPer / 10) * 13);
    }
  });

  return (
    <strong
      className="freshness_standard"
      style={{
        opacity: opacity,
        fontSize: 60,
        fontFamily: 'BusanBada',
        color: '#005eec',
      }}>
      {getFreshnessStandard(freshnessPer)}
    </strong>
  );
};
