import { useState } from "react";

export const useLocalStorage = (key, initialValue) => {
    const [storageValue, SetStorageValue] = useState(() => {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
    });

    const ChangeStorageValue = (value) => {
        const setValue = value instanceof Function ? value(storageValue) : value;
        SetStorageValue(setValue);
        window.localStorage.setItem(key, JSON.stringify(setValue));
    };

    return [storageValue, ChangeStorageValue];
};
