import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function Header({ }) {
    const history = useHistory();
    const location = useLocation();

    return (
        <div className="header_area">
            <div className="header">
                {location.pathname !== "/" && (
                    <span
                        onClick={() => {
                            if (location.pathname === "/result/home") {
                                if (window.bfresh === undefined) {
                                    try {
                                        window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "backPress" }));
                                    } catch (e) {

                                    }
                                }
                                else {
                                    try { window.bfresh.backPress(); } catch (err) { }

                                }
                            }
                            else history.goBack();
                        }}
                        className="icon_back"
                    >
                        <img
                            style={{
                                padding: "13px 19.5px",
                            }}
                            src={require("../images/icon_back.png")}
                            alt="QR스캔"
                            className="QRscan_img" />
                    </span>
                )}
                <h1>
                    <img
                        src={require("../images/header_logo.png")}
                        alt="QR스캔"
                        style={{
                            marginTop: 15,
                        }}
                        className="QRscan_img" />
                </h1>
                <div
                    style={{
                        backgroundColor: "#eeeeee",
                        width: "100%",
                        height: "1px",
                        position: "relative",
                        top: -1,
                    }}
                />
            </div>
        </div>
    )
}