import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Home from "./Home";
import Result from "./Result";
import Header from "./Header";
import Nav from "./Nav";
import UserNotice from "./UserNotice";
import BusinessIntroduction from "./BusinessIntroduction";
import RadioactiveContamination from "./RadioactiveContamination";
import LogisticsFreshness from "./LogisticsFreshness";
import { useLocalStorage } from "../uesLocalStorage";
import BasicPopup from "./BasicPopup";
import "../css/Readme.css";
import "../css/Readme2.css";
import "../css/infocontents.css";
import EventPopup from "./EventPopup";
import EventPage from "./EventPage";
import EventResult from "./EventResult";
import GoBfresh from "./GoBfresh";

// alert(checkMobile())

export default function App() {
  const location = useLocation();
  const history = useHistory();
  const [scanId, setScanId] = useState("");
  const [webViewOn, setWebViewOn] = useState(false);
  const [popupOn, setPopupOn] = useLocalStorage("popupOn", "basic");
  const [eventPopupOn, setEventPopupOn] = useLocalStorage("event", "true");
  const [eventPopupOn_, setEventPopupOn_] = useState(false);
  const [isEventFirst, setIsEventFirst] = useState(true);

  const scan = () => {
    if (window.bfresh === undefined) {
      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "scan" }));
      } catch (e) {}
    } else {
      try {
        window.bfresh.scan();
      } catch (err) {}
    }
  };

  const changeScanId = (e) => {
    const serialNum = e.target.className.split("serial_no=");
    if (serialNum.length === 1) {
      if (window.bfresh === undefined) {
        try {
          window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "showScanIdError" }));
        } catch (e) {}
      } else {
        try {
          window.bfresh.showScanIdError();
        } catch (err) {}
      }
    } else setScanId(serialNum[1]);
  };

  const pressBack = () => {
    if (location.pathname === "/" && eventPopupOn_) {
      setEventPopupOn_(false);
    } else if (popupOn !== false) setPopupOn(false);
    else history.goBack();
  };

  useEffect(() => {
    if (isEventFirst) {
      if (location.pathname === "/") {
        if (eventPopupOn === "true") {
          setEventPopupOn_(true);
        } else {
          let date = new Date();
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const eventCnt = eventPopupOn.split(":")[0];
          const dateArr = eventPopupOn.split(":")[1].split("-");
          if (year >= Number(dateArr[0])) {
            if (month >= Number(dateArr[1])) {
              if (location.pathname === "/" && day > Number(dateArr[3])) {
                setEventPopupOn_(true);
              } else {
                if (year >= 2020 && month >= 11 && day >= 12 && eventCnt === "1차") {
                  setEventPopupOn_(true);
                }
              }
            } else {
              if (year >= 2020 && month >= 11 && day >= 12 && eventCnt === "1차") {
                setEventPopupOn_(true);
              }
            }
          } else {
            if (year >= 2020 && month >= 11 && day >= 12 && eventCnt === "1차") {
              setEventPopupOn_(true);
            }
          }
        }
        setIsEventFirst(false);
      }
    }
  }, [eventPopupOn]);

  useEffect(() => {
    if (location.pathname === "/") setWebViewOn(true);
    // setScanId("GC00003_MAC008L2021070600005");
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      if (window.bfresh === undefined) {
        try {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              funcName: "changePopup",
              value: eventPopupOn_ ? "on" : popupOn !== false ? "on" : "off",
            })
          );
        } catch (e) {}
      } else {
        try {
          window.bfresh.changePopup(eventPopupOn_ ? "on" : popupOn !== false ? "on" : "off");
        } catch (err) {}
      }
    } else {
      if (window.bfresh === undefined) {
        try {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ funcName: "changePopup", value: popupOn !== false ? "on" : "off" })
          );
        } catch (e) {}
      } else {
        try {
          window.bfresh.changePopup(popupOn !== false ? "on" : "off");
        } catch (err) {}
      }
    }
    if (popupOn !== false) document.body.classList.add("no-sroll");
    else document.body.classList.remove("no-sroll");
  }, [popupOn, eventPopupOn_]);

  useEffect(() => {
    if (webViewOn) {
      if (window.bfresh === undefined) {
        try {
          window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "showWebView" }));
        } catch (e) {}
      } else {
        try {
          window.bfresh.showWebView();
        } catch (err) {}
      }
    }
  }, [webViewOn]);

  useEffect(() => {
    if (window.bfresh === undefined) {
      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "changePath", value: location.pathname }));
      } catch (e) {}
    } else {
      try {
        window.bfresh.changePath(location.pathname);
      } catch (err) {}
    }
  }, [location.pathname]);

  return (
    <>
      <Header />
      {scanId !== "" && (
        <div style={{ display: location.pathname.split("/")[1] === "result" ? "block" : "none" }}>
          <Result
            setPopupOn={setPopupOn}
            webViewOn={webViewOn}
            popupOn={popupOn}
            scanId={scanId}
            setWebViewOn={setWebViewOn}
          />
        </div>
      )}
      <Switch>
        <Route exact path="/user_notice">
          <UserNotice />
        </Route>
        <Route exact path="/radioactive_contamination">
          <RadioactiveContamination />
        </Route>
        <Route exact path="/business_introduction">
          <BusinessIntroduction />
        </Route>
        <Route exact path="/logistics_freshness">
          <LogisticsFreshness />
        </Route>
        <Route exact path="/event_page">
          <EventPage />
        </Route>
        <Route exact path="/event_result">
          <EventResult />
        </Route>
        <Route exact path="/">
          <Home scan={scan} />
        </Route>
        <Route exact path="/bfresh">
          <GoBfresh />
        </Route>
      </Switch>
      <Nav scan={scan} />
      {/* {location.pathname === "/" ? eventPopupOn_ ?
                <EventPopup setEventPopupOn_={setEventPopupOn_} setEventPopupOn={setEventPopupOn} /> :
                popupOn === "basic" && location.pathname === "/" && <BasicPopup popupOn={popupOn} setPopupOn={setPopupOn} /> : popupOn === "basic" && location.pathname === "/" && <BasicPopup popupOn={popupOn} setPopupOn={setPopupOn} />}
            {/* {location.pathname === "/" &&
                popupOn === "basic" && <BasicPopup popupOn={popupOn} setPopupOn={setPopupOn} />} */}
      {location.pathname === "/" && popupOn === "basic" && location.pathname === "/" && (
        <BasicPopup popupOn={popupOn} setPopupOn={setPopupOn} />
      )}
      {/* {location.pathname === "/" &&
                popupOn === "basic" && <BasicPopup popupOn={popupOn} setPopupOn={setPopupOn} />} */}
      <button
        id="changeScanId"
        style={{
          display: "hidden",
          top: 0,
          left: 0,
          width: 0,
          height: 0,
          position: "fixed",
          zIndex: -1,
        }}
        onClick={changeScanId}
      />
      <button
        id="pressBack"
        style={{
          display: "hidden",
          width: 0,
          height: 0,
          top: 0,
          left: 0,
          position: "fixed",
          zIndex: -1,
        }}
        onClick={pressBack}
      />
    </>
  );
}
