import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const homeOn = require("../images/tab_icon_home_on.png")
const homeOff = require("../images/tab_icon_home_off.png")
const businessOn = require("../images/tab_icon_bfresh_on.png")
const businessOff = require("../images/tab_icon_bfresh_off.png")

export default function Nav({ scan }) {
    const history = useHistory();
    const location = useLocation();

    return (
        <div style={{
            height: 89,
            position: "fixed",
            bottom: 0,
            zIndex: 1000,
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            backgroundColor: "#ffffff",
            borderTop: "1px solid #eeeeee"
        }}>
            <div
                onClick={() => {
                    history.push("/")
                }}
                style={{ textAlign: "center" }}
            >
                <img
                    style={{ marginTop: 24.5 }}
                    src={location.pathname === "/" ? homeOn : homeOff} />
            </div>
            <div style={{ textAlign: "center" }}>
                <img
                    onClick={scan}
                    style={{ position: "relative", bottom: 20 }}
                    src={require("../images/tab_icon_QR.png")} />
            </div>
            <div
                onClick={() => {
                    history.push("/business_introduction")
                }}
                style={{ textAlign: "center" }}
            >
                <img
                    style={{ marginTop: 24.5 }}
                    src={location.pathname === "/business_introduction" ? businessOn : businessOff}
                />
            </div>
        </div>
    )
}
