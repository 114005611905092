import React, { useEffect, useState } from 'react';

export default function UserNotice({ isGetDataSuccess4, setPopupOn }) {
  const [opacity, setOpacity2] = useState(0.8);

  useEffect(() => {
    if (isGetDataSuccess4) setOpacity2(0);
  }, [isGetDataSuccess4]);

  return (
    <>
      <>
        <img
          src={require('../../images/floating_graph_off.png')}
          style={{
            position: 'fixed',
            width: 136,
            height: 136,
            bottom: 140,
            right: 20,
            zIndex: 100,
            opacity: opacity,
            transition: '1s',
          }}></img>
        <img
          src={require('../../images/floating_graph.png')}
          style={{
            position: 'fixed',
            width: 136,
            height: 136,
            bottom: 140,
            right: 20,
            zIndex: 100,
            opacity: opacity === 0.8 ? 0 : 1,
            transition: '1s',
          }}></img>
        <div
          onClick={() => {
            if (isGetDataSuccess4) setPopupOn('graph');
          }}
          style={{
            position: 'fixed',
            width: 111.5,
            height: 111.5,
            bottom: 163,
            borderRadius: 55.5,
            zIndex: 100,
            right: 33,
          }}></div>
      </>
    </>
  );
}
