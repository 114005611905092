import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const curDate = new Date();
const endDate = new Date("2020", "10", "12", "00", "00", "00");

const bool = curDate.getTime() > endDate.getTime()

export default function EventPopup({ setEventPopupOn_, setEventPopupOn }) {
    const history = useHistory();
    // useEffect(() => {
    //     return (() => {
    //         eventPopupOn(false)
    //     })
    // }, []);

    return (
        <div>
            <section
                onClick={() => {
                    // setEventPopupOn_(false)
                }}
                className="dg_dark"
                style={{ zIndex: 10000 }}
            />
            <section className="popup_notice">
                <div
                    className="notice"
                    style={{ overflowY: "auto", paddingBottom: 0 }}
                >
                    <div>
                        {!bool ? <img onClick={() => {
                            if (window.bfresh === undefined) {
                                try { window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "showBrowser", value: "https://blockchainbusan.kr/boardview.do?boardnum=165&boardtype=notice" })); } catch (e) { }
                            }
                            else {
                                try { window.bfresh.showBrowser("https://blockchainbusan.kr/boardview.do?boardnum=165&boardtype=notice") } catch (e) { }

                            }
                        }} style={{ width: "100%", }} src={require("../images/eventPopup.jpg")} /> :
                            <div>
                                <img style={{ width: "100%", }} src={require("../images/201109_bfresh_aos.jpg")} />
                                <div onClick={() => {
                                    history.push("/event_page")
                                }} style={{ width: "36%", height: 64, position: "absolute", top: 325, left: 210 }}></div>
                                <div onClick={() => {
                                    if (window.bfresh === undefined) {
                                        try { window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "showBrowser", value: "https://bpass.page.link/event" })); } catch (e) { }
                                    }
                                    else {
                                        try { window.bfresh.showBrowser("https://bpass.page.link/event") } catch (e) { }
                                    }
                                }} style={{ width: "36%", height: 64, position: "absolute", top: 703, left: 210 }}></div>
                            </div>}
                    </div>
                    <div
                        style={{
                            textAlign: "center",
                            height: 90,
                            fontSize: 24,
                            fontWeight: 500,
                            borderTop: "1px solid #cecece",
                            display: "grid",
                            gridTemplateColumns: "1.5fr 1fr"
                        }}
                    >
                        <div
                            style={{ fontWeight: 500, paddingTop: 27, }}
                            onClick={() => {
                                let date = new Date();
                                var dayOfMonth = date.getDate()
                                date.setDate(dayOfMonth + 7)
                                const year = date.getFullYear()
                                const month = date.getMonth() + 1 < 10 ? "0" + date.getMonth() + 1 : date.getMonth() + 1;
                                const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                                const asd = !bool ? "1차:" : "2차:";
                                setEventPopupOn(asd + year + '-' + month + '-' + day)
                                setEventPopupOn_(false)
                            }}
                        >
                            일주일간 보지 않기
                        </div>
                        <div

                            onClick={() => {
                                setEventPopupOn_(false)
                            }}
                            style={{ color: "#ffffff", fontWeight: 500, paddingTop: 27, backgroundColor: "#1159da" }}
                        >
                            닫기
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}
