import React, { useEffect } from "react";

function checkMobile() {

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if (varUA.indexOf('android') > -1) {
        //안드로이드
        return "android";
    } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
        //IOS
        return "ios";
    } else {
        //아이폰, 안드로이드 외
        return "other";
    }

}

var timer;	// 타이머
var schInterval; // 인터벌

function clearTimer() {
    clearInterval(schInterval);
    clearTimeout(timer);
}

function intervalSch() {
    if (document.webkitHidden || document.hidden) {
        clearTimer();
        alert("앱이 설치 되어 있습니다.");
    } else {
        console.log("타이머 동작");
    }
}

function gogo() {
    const Platform = checkMobile()
    window.location.href = Platform === "ios" ? "bkp-0000000005://" : "intent://play?action=goHome/#Intent;scheme=bpnsolutionbfresh;package=com.bpns.m.block;end";
    schInterval = setInterval(intervalSch, 500);
    timer = setTimeout(function () {
        window.location.href = Platform === "ios" ? "http://apps.apple.com/kr/app/bfresh/id1538683527" : "intent://play?action=goHome/#Intent;scheme=bpnsolutionbfresh;package=com.bpns.m.block;end";
        clearInterval(schInterval);
    }, 2000);

}



export default function GoBfresh() {
    useEffect(() => {
        gogo();
        console.log("?")
    }, [])

    return (
        <></>
    );
}