import React from "react";

export default function RadioactiveContamination({ }) {
    return (
        <div
            style={{
                height: "calc(100% - 96px)",
                width: "100%",
                position: "fixed",
                zIndex: 100,
                backgroundColor: "#ffffff",
                overflow: "auto",
                top: 96,
            }}
        >
            <div id="cont_area">
                <div className="cont_tit2">
                    <h1>
                        비상시 방사능 오염 측정 기준 적용
                        <br />
                        <span className="sub_tit2">&#40;한국원자력안전기술원 기준 적용&#41;</span>
                    </h1>
                </div>
                <div
                    className="contents"
                    style={{
                        borderBottomWidth: 0,
                        marginTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0
                    }}>
                    <p>
                        <ul>
                            <li>
                                <span className="weight_bold">&#91;안전&#93; 1μSv/h 미만</span>
                                <ol>
                                    <li>&#45; 피검사 상품의 방사능 오염도 기준 이하</li>
                                    <li>&#45; 상품의 유통 거래 지속</li>
                                </ol>
                            </li>
                            <br />
                            <li>
                                <span className="weight_bold">
                                    &#91;주의/정밀측정 요망&#93; 1μSv/h~100μSv/h
                                </span>
                                <ol>
                                    <li>
                                        &#45; 부산 스마트 콜드체인 상황실에 해당 방사능 오염 상황
                                        즉시 통보&#40;자동&#41;
                                    </li>
                                    <li>
                                        &#45; 상품의 유통 즉시 중단&#40;기준에 따라 폐기 및 반품
                                        처리&#41;
                                    </li>
                                    <li>
                                        &#45; 접촉자 즉시 연락후 제염 조치&#40;탈의, 세수 및
                                        전신세척 등&#41; 통보 및 재측정 지시, 제염조치 이후에도
                                        방사선량률 측정 수치가 크게 변하지 않는 경우 기준에 따라
                                        지정병원 후송 또는 제염 재실시
                                    </li>
                                </ol>
                            </li>
                            <br />
                            <li>
                                <span className="weight_bold">&#91;위험&#93; 100μSv/h 초과</span>
                                <ol>
                                    <li>
                                        &#45; 부산 스마트 콜드체인 상황실에 해당 방사능 오염 상황
                                        즉시 통보&#40;자동&#41;
                                    </li>
                                    <li>
                                        &#45; 상품의 유통 즉시 중단 후 상황 발생 장소에
                                        정지&#40;이동금지&#41;
                                    </li>
                                    <li>
                                        &#45; 상황실은 즉시 119 및 지정병원에 연락 후 지시에 따라
                                        행동
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    );
}