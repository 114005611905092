import React, { useEffect } from "react";

export default function LogisticsFreshness() {
    return (
        <div
            style={{
                height: "calc(100% - 96px)",
                width: "100%",
                position: "fixed",
                zIndex: 100,
                backgroundColor: "#ffffff",
                overflow: "auto",
                top: 96,
            }}
        >
            <div id="cont_area" >
                <div className="cont_tit2">
                    <h1>물류신선도 기준</h1>
                </div>
                <div
                    className="contents"
                    style={{
                        borderBottomWidth: 0,
                        marginTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0
                    }}>
                    <p className="point_txt">
                        제품의 유통.물류 총 시간에서 비신선 환경 노출 비중에 가중치를 반영하여 산정
                    </p>
                    <br />
                    <p>
                        <span className="weight_bold">&#91;최고신선&#93;</span> 물류신선도 95&#37;
                        이상
                        <br />
                        <span className="weight_bold">&#91;매우신선&#93;</span> 물류신선도 90&#37;
                        &#126; 94.99&#37;
                        <br />
                        <span className="weight_bold">&#91;보통신선&#93;</span> 물류신선도 80&#37;
                        &#126; 89.99&#37;
                        <br />
                        <span className="weight_bold">&#91;보통&#93;</span> 물류신선도 70&#37;
                        &#126; 79.99&#37;
                        <br />
                        <span className="weight_bold">&#91;신선기준 이하&#93;</span> 물류신선도
                        70&#37; 미만
                    </p>
                    <br />
                    <p>
                        <ul>
                            <li>
                                <span className="weight_bold">※ 물류신선도 산정 기준</span>
                                <ol>
                                    <li>
                                        &#45; 신선제품의 유통.물류 총 시간을
                                        단위시간&#40;10분&#41;으로 나누어 단위물류신선도 산정
                                    </li>
                                    <li>&#45; 각 단위물류신선도에 해당 시간별 산정가중치 적용</li>
                                    <li>&#45; 수산전문인&#40;부경대학교&#41;의 자문의견 반영</li>
                                    <li>
                                        &#45; &#40;예정&#41; 축적된 유통/물류 빅데이터를 활용하여
                                        물류신선도 반영
                                    </li>
                                </ol>
                            </li>
                            <br />
                            <li>
                                <span className="weight_bold">
                                    ※ 물류신선도 산정 예시
                                    <br />
                                    &#91;물류신선도 산정예시 조건&#93;
                                </span>
                                <ol>
                                    <li>
                                        &#45; 물류신선도는 본 플랫폼 서비스에서 신선물류 산정에
                                        최초로 적용하는 개념으로, 36시간을 신선물류&#40;수산물&#41;
                                        한계시간으로 기준하고, 그 시간을 10분단위로 216구간으로
                                        나누어 각 구간별로 비신선환경 노출시간을 각 단위신선도에
                                        누적 반영한 것임.
                                    </li>
                                    <li>
                                        &#45; 아래 산정 예시는 양륙한 수산물을 3시간&#40;18구간&#41;
                                        동안 이송하는 동안에 적용되는 사례를 현 산정방법에 따라
                                        계산한 것임.
                                    </li>
                                </ol>
                            </li>
                            <br />
                        </ul>
                        <table className="table_fresh">
                            <tr>
                                <th colspan="4" style={{ textAlign: "left" }}>
                                    &#42;&#42; 물류신선도 산정식&#40;물류신선가중치 합산방식&#41;
                                </th>
                            </tr>
                            <tr className="table_th">
                                <th>단위시간&#40;10분&#41;</th>
                                <th>단위신선기준치</th>
                                <th>비신선노출시간</th>
                                <th>단위신선도</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>99.861</td>
                                <td>10.00</td>
                                <td>99.7897</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>99.583</td>
                                <td>0.00</td>
                                <td>99.5833</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>99.583</td>
                                <td>0.00</td>
                                <td>99.5833</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>99.444</td>
                                <td>0.00</td>
                                <td>99.4444</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>99.306</td>
                                <td>0.00</td>
                                <td>99.3056</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>99.167</td>
                                <td>0.00</td>
                                <td>99.1667</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>99.028</td>
                                <td>0.00</td>
                                <td>99.0278</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>98.889</td>
                                <td>0.00</td>
                                <td>98.8889</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>98.750</td>
                                <td>10.00</td>
                                <td>98.1071</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>98.611</td>
                                <td>0.00</td>
                                <td>98.6111</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>98.472</td>
                                <td>0.00</td>
                                <td>98.4722</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>98.333</td>
                                <td>0.00</td>
                                <td>98.3333</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>98.194</td>
                                <td>0.00</td>
                                <td>98.1944</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>98.056</td>
                                <td>0.00</td>
                                <td>98.0555</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>97.917</td>
                                <td>0.00</td>
                                <td>97.9167</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>97.778</td>
                                <td>0.00</td>
                                <td>97.7778</td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>97.639</td>
                                <td>0.00</td>
                                <td>97.6389</td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>97.500</td>
                                <td>10.00</td>
                                <td>96.2143</td>
                            </tr>
                            <tr style={{ borderTop: "1px solid #bdbdbd" }}>
                                <td colspan="2"></td>
                                <th>&#42;물류신선도</th>
                                <th>98.562</th>
                            </tr>
                        </table>
                    </p>
                </div>
            </div>
        </div >
    );
}