import React, { useEffect } from "react";
import TMapMain from "./TMapMain";

// const temps = [
//   {
//     i_serial_no: null,
//     trans_div: "",
//     trans_time: "2020-08-21 08:00:00",
//     trans_state: "양륙출고",
//     trans_loc: "공동어판장",
//     trans_elapsed_time: "",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "운송",
//     trans_time: "",
//     trans_state: "",
//     trans_loc: "(주)비피앤솔루션",
//     trans_elapsed_time: "25분",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "",
//     trans_time: "2020-08-21 08:25:00",
//     trans_state: "하차/입고",
//     trans_loc: "(주)퀘스트엑스",
//     trans_elapsed_time: "",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "선별",
//     trans_time: "",
//     trans_state: "",
//     trans_loc: "(주)퀘스트엑스",
//     trans_elapsed_time: "1시간05분",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "",
//     trans_time: "2020-08-21 10:30:00",
//     trans_state: "출고/상차",
//     trans_loc: "(주)퀘스트엑스",
//     trans_elapsed_time: "",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "운송",
//     trans_time: "",
//     trans_state: "",
//     trans_loc: "(주)비피앤솔루션",
//     trans_elapsed_time: "35분",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "",
//     trans_time: "2020-08-21 11:05:00",
//     trans_state: "하차/입고",
//     trans_loc: "(주)파도소리",
//     trans_elapsed_time: "",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "가공",
//     trans_time: "",
//     trans_state: "",
//     trans_loc: "(주)파도소리",
//     trans_elapsed_time: "4시간10분",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "",
//     trans_time: "2020-08-21 15:25:00",
//     trans_state: "출고/상차",
//     trans_loc: "(주)파도소리",
//     trans_elapsed_time: "",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "운송",
//     trans_time: "",
//     trans_state: "",
//     trans_loc: "(주)비피앤솔루션",
//     trans_elapsed_time: "1시간20분",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "사고",
//     trans_time: "",
//     trans_state: "정차",
//     trans_loc: "",
//     trans_elapsed_time: "",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "사고",
//     trans_time: "",
//     trans_state: "문열림",
//     trans_loc: "",
//     trans_elapsed_time: "",
//   },
//   {
//     i_serial_no: null,
//     trans_div: "",
//     trans_time: "2020-08-21 16:45:00",
//     trans_state: "하차/입고",
//     trans_loc: "장림탑마트",
//     trans_elapsed_time: "",
//   },
// ];

export default function LogisticsInfo({
  setWebViewOn,
  basicInfoRef,
  mapPopupOn,
  webViewOn,
  setmapPopupOn,
  selInfo,
  isGraphAnimStart,
  data,
  draggable,
  setPopupStatus,
  setDraggable,
  logisticsInfoRef,
}) {
  const GetChangeLocalDateToString = (curDateTime) => {
    if (curDateTime === undefined || curDateTime === null || curDateTime === "") {
      return "";
    } else {
      return `
            ${curDateTime.substr(0, 4)}. 
            ${curDateTime.substr(5, 2)}. 
            ${curDateTime.substr(8, 2)}. 
            ${curDateTime.substr(11, 2)} : 
            ${curDateTime.substr(14, 2)}
        `;
    }
  };

  let qweqwe2 = [];
  const asd = () => {
    let tkrh = [];
    let temp;
    let j = -1;
    for (let i = 0; i < data.transitInfos.length; i++) {
      const transitInfo = data.transitInfos[i];
      if (transitInfo.trans_div === "") {
        j++;
        qweqwe2.push({ main: transitInfo, sub: [] });
      } else if (transitInfo.trans_div !== "") {
        qweqwe2[j].sub.push(transitInfo);
      }
    }
  };
  asd();

  useEffect(() => {
    // console.log("Logistick");
  });

  const getLogisticsInfoItems = () => {
    let circulationCnt = 1;
    let logisticsCnt = 1;
    let processingCnt = 1;
    let rawMaterials = 1;

    return data.logisticsInfos.map((logisticsInfo, i) => {
      // console.log(logisticsInfo.companyname);
      let className;
      let typeName;
      switch (logisticsInfo.type) {
        case "물류":
          className = "distribution";
          typeName = `물류${logisticsCnt++}차`;
          break;
        case "선별":
          className = "circulation";
          typeName = `선별${circulationCnt++}차`;
          break;
        case "원재료":
          className = "circulation";
          typeName = `원재료${rawMaterials++}차`;
          break;
        case "가공":
          className = "circulation";
          typeName = `가공${processingCnt++}차`;
          break;
      }

      return (
        <div key={i} className={`box_${className}`}>
          <div className={`${className}_no`}>{typeName}</div>
          <div className={`${className}_txt`}>
            <p className="commaText" style={{ width: window.innerWidth - 300 }}>
              <span className="companyname">{logisticsInfo.companyname}</span>
              <br />
              {logisticsInfo.address !== "" &&
                logisticsInfo.address !== null &&
                logisticsInfo.address !== undefined &&
                ` (${logisticsInfo.address})`}
            </p>
            <p>
              입고 &nbsp;
              <strong>
                {logisticsInfo.wearingTime !== null && GetChangeLocalDateToString(logisticsInfo.wearingTime)}
              </strong>
            </p>
            <p>
              {logisticsInfo.releaseTime !== null && (
                <>
                  출고 &nbsp;
                  <strong>{GetChangeLocalDateToString(logisticsInfo.releaseTime)}</strong>
                </>
              )}
            </p>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div
        style={{
          fontSize: 34,
          color: "191919",
          fontWeight: "bold",
          fontFamily: "Noto Sans KR",
        }}
      >
        물류/유통정보
      </div>
      <div
        style={{
          top: 55,
          width: 20,
          marginLeft: 20,
          position: "relative",
        }}
      >
        <div
          style={{
            height: "100%",
            marginBottom: 170,
          }}
        >
          {qweqwe2.map((data, i) => {
            const state = data.main.trans_state;
            const title = data.main.trans_loc;
            const time = data.main.trans_time.substr(11, 5);
            let div, divTime, endTitle;
            let divs = [];
            let tkrhs = [];
            let x = -1;
            for (let i = 0; i < data.sub.length; i++) {
              const sub = data.sub[i];
              if (sub.trans_div !== "사고") {
                divs.push({
                  div: sub.trans_div,
                  divTime: sub.trans_elapsed_time,
                  endTitle: sub.trans_loc,
                  tkrhs: [],
                });
                x++;
              } else {
                let tkrhss = divs[x].tkrhs === undefined ? [] : divs[x].tkrhs;
                tkrhss.push(sub.trans_state);
                divs[x] = { ...divs[x], tkrhs: tkrhss };
              }
            }

            return (
              <div
                style={{
                  backgroundColor: i + 1 === qweqwe2.length ? "#00000000" : "#f0f4f9",
                  borderRadius: 10,
                  paddingBottom: qweqwe2.length === i + 1 ? 120 : 0,
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: divs.length === 0 ? 20 : 50 + 50 * divs.length,
                    display: "grid",
                    gridTemplateColumns: "60px 535px 20px",
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: i === 0 || i + 1 === qweqwe2.length ? "#005eec" : "#90a4ae",
                      borderRadius: 10,
                    }}
                  ></div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        top: -2,
                      }}
                    >
                      <span style={{ fontSize: 25 }}>{time}</span>
                      <span
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                          fontFamily: "Noto Sans KR",
                        }}
                      >
                        &nbsp; {state}
                      </span>
                      &nbsp; &nbsp;
                      <span style={{ fontSize: 25 }}>{title}</span>
                    </div>
                    {divs.map((data, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            width: "100%",
                            marginTop: 23,
                            fontSize: 20,
                            color: "#9b9b9b",
                            fontFamily: "Noto Sans KR",
                            position: "relative",
                            top: -2,
                          }}
                        >
                          <span style={{}}>{`[${data.div}] ${data.divTime}`}</span>
                          &nbsp;
                          <span style={{}}>{data.endTitle}</span>
                          {data.tkrhs.map((takrhs) => {
                            return (
                              <span
                                style={{
                                  backgroundColor: "#e62b00",
                                  height: 29,
                                  display: "inline-block",
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  fontFamily: "Noto Sans KR",
                                  fontSize: 20,
                                  color: "#ffffff",
                                  paddingTop: 3,
                                  borderRadius: 8,
                                  marginLeft: 10,
                                }}
                              >
                                {takrhs}
                              </span>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      height: i === 0 ? 0 : 20,
                      width: 20,
                      backgroundColor: "#f0f4f9",
                      left: -595,
                      top: -10,
                      zIndex: -1,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <section id="distribution" ref={logisticsInfoRef}>
        {/* {data.temperatureHumidityInfos.length !== 0 && (
                <Graph
                    basicInfoRef={basicInfoRef}
                    logisticsInfoRef={logisticsInfoRef}
                    selInfo={selInfo}
                    isGraphAnimStart={isGraphAnimStart}
                    data={data}
                />
            )} */}
        {data.maps.length !== 0 && data.maps.length !== undefined && (
          <TMapMain setWebViewOn={setWebViewOn} webViewOn={webViewOn} goods={data} />
        )}
        {/* <div className="box_circulation_area">{getLogisticsInfoItems()}</div> */}
      </section>
    </>
  );
}
