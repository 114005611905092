/*global kakao*/
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const g = (t) => {
  return Number(t);
};
const Aasd = ({ asdasd }) => {
  const [yPos, setYPos] = useState(100);
  const [onOff, setOnOff] = useState(asdasd);
  useEffect(() => {
    if (yPos === 100) {
      setYPos(40);
    } else if (yPos === 101) {
      setTimeout(() => {
        setYPos(40);
      }, 1000);
    } else {
      setTimeout(() => {
        setYPos(101);
      }, 1000);
    }
  });

  useEffect(() => {
    setOnOff(asdasd);
  }, [asdasd]);

  return (
    <img
      style={{
        width: 50,
        marginTop: yPos,
        transition: '1s',
        opacity: onOff ? 1 : 0,
      }}
      src={require('../../images/icon_more.png')}></img>
  );
};

export default function LogisticsFreshnessData({ goods }) {
  const history = useHistory();
  const [asdasd, setasdasd] = useState(true);
  const scrollRef = useRef();
  const [avg_temp, set_avg_temp] = useState(0);
  const [avg_humi, set_avg_humi] = useState(0);

  console.log(goods);
  const getTimeForm = (circulationTotalMinute) => {
    const hours = Math.floor(circulationTotalMinute / 60);
    const minutes = circulationTotalMinute % 60;
    return `${hours}시간 ${minutes}분`;
  };

  useEffect(() => {
    let humis = 0;
    let temps = 0;
    if (goods.temperatureHumidityInfos) {
      goods.temperatureHumidityInfos.map((data) => {
        humis += Number(data.humi);
        temps += Number(data.temp);
      });
      set_avg_temp(Number(String(temps / goods.temperatureHumidityInfos.length).substr(0, 3)));
      set_avg_humi(humis / goods.temperatureHumidityInfos.length);

      if (
        scrollRef.current.scrollTop + scrollRef.current.offsetHeight + 10 >
          scrollRef.current.scrollHeight &&
        asdasd
      ) {
        setasdasd(false);
      } else if (
        scrollRef.current.scrollTop + scrollRef.current.offsetHeight + 10 <=
          scrollRef.current.scrollHeight &&
        !asdasd
      ) {
        setasdasd(true);
      }
    }
  }, [goods]);

  const st = goods.startTime;
  const et = goods.endTime;
  var startDate = new Date(
    g(st.substr(0, 4)),
    g(st.substr(5, 2)),
    g(st.substr(8, 2)),
    g(st.substr(11, 2)),
    g(st.substr(14, 2)),
  );
  var endDate = new Date(
    g(et.substr(0, 4)),
    g(et.substr(5, 2)),
    g(et.substr(8, 2)),
    g(et.substr(11, 2)),
    g(et.substr(14, 2)),
  );
  var tmp = (endDate.getTime() - startDate.getTime()) / 60000 / 60;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          bottom: 100,
          height: 150,
          width: '100%',
          zIndex: 1000000,
          textAlign: 'center',
        }}>
        <Aasd asdasd={asdasd} />
      </div>
      <div
        ref={scrollRef}
        id="asdasdasd"
        style={{
          height: 'calc(100% - 185px)',
          width: '100%',
          position: 'fixed',
          zIndex: 1000,
          top: 95,
          backgroundColor: '#ffffff',
          overflowY: 'auto',
        }}
        onScroll={(event) => {
          if (
            scrollRef.current.scrollTop + scrollRef.current.offsetHeight + 10 >
              scrollRef.current.scrollHeight &&
            asdasd
          ) {
            setasdasd(false);
          } else if (
            scrollRef.current.scrollTop + scrollRef.current.offsetHeight + 10 <=
              scrollRef.current.scrollHeight &&
            !asdasd
          ) {
            setasdasd(true);
          }
        }}>
        <div style={{ backgroundColor: '#f0f3f8' }}>
          <div style={{ height: 60 }}></div>
          <div style={{ marginLeft: 32 }}>
            <span
              style={{
                fontSize: 49,
                fontFamily: 'Noto Sans KR',
                color: '#005eec',
                fontWeight: 'bold',
              }}>
              {goods.freshDiv}
            </span>
            <img
              style={{ top: -10, left: 10, position: 'relative' }}
              src={require('../../images/icon_smile.png')}
            />
            <span
              onClick={() => {
                history.push('/logistics_freshness');
              }}
              style={{
                fontSize: 24,
                fontFamily: 'Noto Sans KR',
                color: '#788799',
                fontWeight: '400',
                marginLeft: 250,
                // cursor: "pointer",
                position: 'relative',
                top: -25,
              }}>
              물류신선도 기준 {'>'}
            </span>
            <div>
              <div style={{ width: 300, display: 'inline-block' }}>
                <div style={{ marginTop: 40 }}>
                  <div
                    style={{
                      fontSize: 24,
                      color: '#888888',
                      fontFamily: 'Noto Sans KR',
                      fontWeight: '300',
                    }}>
                    평균 온도
                  </div>
                  <div
                    style={{
                      fontSize: 49,
                      color: '#191919',
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 'bold',
                      marginTop: 20,
                    }}>
                    {goods.averageTemperature}ºC
                  </div>
                </div>
              </div>
              <div style={{ width: 300, display: 'inline-block' }}>
                <div style={{}}>
                  <div
                    style={{
                      width: 350,
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                    }}>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#005eec',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: 'bold',
                      }}>
                      신선
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#005eec',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                      매우신선
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#005eec',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: 'bold',
                        textAlign: 'right',
                      }}>
                      신선
                    </div>
                    <img
                      style={{
                        top: 177,
                        left: 337 + (323 * (Number(goods.averageTemperature) + 10)) / 20,
                        // left: 670,
                        position: 'absolute',
                      }}
                      src={require('../../images/barchart_circle.png')}
                    />
                  </div>
                  <img
                    style={{ marginTop: 5 }}
                    src={require('../../images/barchart_temperature.png')}
                  />
                  <div
                    style={{
                      width: 350,
                      display: 'grid',
                      marginTop: 5,
                      gridTemplateColumns: '1fr 1fr 1fr',
                    }}>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#9b9b9b',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: '400',
                      }}>
                      -10ºC
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#9b9b9b',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: '400',
                        textAlign: 'center',
                      }}>
                      0
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#9b9b9b',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: '400',
                        textAlign: 'right',
                      }}>
                      10ºC
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style={{ width: 300, display: 'inline-block', marginTop: 10 }}>
                <div style={{ marginTop: 40 }}>
                  <div
                    style={{
                      fontSize: 24,
                      color: '#888888',
                      fontFamily: 'Noto Sans KR',
                      fontWeight: '300',
                    }}>
                    총 유통시간
                  </div>
                  <div
                    style={{
                      fontSize: 49,
                      color: '#191919',
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 'bold',
                      marginTop: 20,
                    }}>
                    {getTimeForm(goods.circulationTotalMinute)}
                  </div>
                </div>
              </div>
              <div style={{ width: 300, display: 'inline-block' }}>
                <div style={{}}>
                  <div
                    style={{
                      width: 350,
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr',
                    }}>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#005eec',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: 'bold',
                      }}>
                      매우신선
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#005eec',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}></div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#005eec',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: 'bold',
                        textAlign: 'right',
                      }}>
                      신선
                    </div>
                  </div>
                  <img style={{ marginTop: 5 }} src={require('../../images/barchart_time.png')} />
                  <div
                    style={{
                      width: 350,
                      display: 'grid',
                      marginTop: 5,
                      gridTemplateColumns: '1fr 1fr 1fr',
                    }}>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#9b9b9b',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: '400',
                      }}>
                      0
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#9b9b9b',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: '400',
                        textAlign: 'center',
                      }}></div>
                    <div
                      style={{
                        fontSize: 20,
                        color: '#9b9b9b',
                        fontFamily: 'Noto Sans KR',
                        fontWeight: '400',
                        textAlign: 'right',
                      }}>
                      36h
                    </div>
                  </div>
                  <img
                    style={{
                      top: 305,
                      left: 337 + (323 * tmp) / 36 > 670 ? 670 : 337 + (323 * tmp) / 36,
                      // left: 670,
                      position: 'absolute',
                    }}
                    src={require('../../images/barchart_circle.png')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              backgroundColor: '#fff',
              marginTop: 60,
              borderTop: '1px dotted #b4c7de',
            }}>
            <div
              style={{
                marginTop: 60,
                marginLeft: 32,
                color: '#191919',
                fontSize: 34,
                fontFamily: 'Noto Sans KR',
                fontWeight: 'bold',
              }}>
              온도/습도 변화 상세
            </div>
            <div
              style={{
                height: 2,
                height: '2px',
                backgroundColor: '#191919',
                marginLeft: 32,
                marginRight: 32,
                marginTop: 27,
              }}></div>
            <div
              style={{
                marginLeft: 32,
                marginRight: 32,
                height: 60,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                borderBottom: '2px solid #bdbdbd',
                lineHeight: 2.4,
              }}>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 24,
                  color: '#191919',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 'bold',
                }}>
                시간
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 24,
                  color: '#191919',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 'bold',
                }}>
                온도
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 24,
                  color: '#191919',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 'bold',
                }}>
                습도
              </div>
            </div>
            <div
              style={{
                marginLeft: 32,
                marginRight: 32,
                height: 60,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                lineHeight: 2.4,
              }}>
              {goods.temperatureHumidityInfos &&
                goods.temperatureHumidityInfos.map((data, i) => {
                  return (
                    <>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: 24,
                          color: '#191919',
                          fontFamily: 'Noto Sans KR',
                          fontWeight: '500',
                        }}>
                        {data.time.substr(8, 2) + ':' + data.time.substr(10, 2)}
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: 24,
                          color: '#d32f2f',
                          fontFamily: 'Noto Sans KR',
                          fontWeight: '500',
                        }}>
                        {data.temp + 'ºC'}
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: 24,
                          color: '#0079d7',
                          fontFamily: 'Noto Sans KR',
                          fontWeight: '500',
                        }}>
                        {data.humi + '%'}
                      </div>
                    </>
                  );
                })}
              <div
                style={{
                  marginLeft: 32,
                  marginRight: 32,
                  height: 60,
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  lineHeight: 2.4,
                }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
