import React, { useEffect, useState } from 'react';
import { getData2 } from './axios';
import loading_1 from '../../images/loading/loading_1.png';
import loading_2 from '../../images/loading/loading_2.png';
import loading_3 from '../../images/loading/loading_3.png';
import loading_4 from '../../images/loading/loading_4.png';
import loading_5 from '../../images/loading/loading_5.png';

export default function ResultPopup({
  setPopupOn,
  scanId,
  resultPopupData,
  setResultPopupData,
  loadingImagePos,
  setLoadingImagePos,
}) {
  const GetData2 = async () => {
    console.log('???');
    try {
      const data = await getData2(scanId);
      setResultPopupData(data);
    } catch (err) {}
  };

  const getDateForm = () => {
    const date = resultPopupData.chain_check_time.replace(/[^0-9]/g, '');
    return (
      date.substr(0, 4) +
      '년 ' +
      date.substr(4, 2) +
      '월 ' +
      date.substr(6, 2) +
      '일 ' +
      date.substr(8, 2) +
      '시 ' +
      date.substr(10, 2) +
      '분 ' +
      date.substr(12, 2) +
      '초 '
    );
  };

  useEffect(() => {
    GetData2();
  }, []);

  useEffect(() => {
    if (resultPopupData === null) {
      setTimeout(() => {
        setLoadingImagePos(loadingImagePos === 4 ? 0 : loadingImagePos + 1);
      }, 200);
    } else {
      if (loadingImagePos !== false) {
        setLoadingImagePos(false);
      }
    }
  }, [resultPopupData, loadingImagePos]);

  const getImage = () => {
    switch (loadingImagePos) {
      case 0:
        return loading_1;
      case 1:
        return loading_2;
      case 2:
        return loading_3;
      case 3:
        return loading_4;
      case 4:
        return loading_5;
      default:
        break;
    }
  };

  return (
    <div className="popup_verification">
      {/* {resultPopupData !== null && ( */}
      <>
        <section
          onClick={() => {
            setPopupOn(false);
          }}
          className="dg_dark"
          style={{ zIndex: 10000 }}
        />
        {loadingImagePos !== false && resultPopupData === null ? (
          <div
            style={{
              zIndex: 10000000,
              position: 'fixed',
              left: 'calc(50% - 70px)',
              top: 'calc(50% - 70px)',
            }}>
            <img style={{ zIndex: 10000000 }} src={getImage()} alt="loading" />
          </div>
        ) : (
          <section className="popup_result">
            <div className="popup_cont">
              <p
                className="popup_tit"
                style={{
                  fontSize: 35,
                  color: '#191919',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 'bold',
                }}>
                블록체인 데이터
                <br />
                검증결과
              </p>
              <p
                className="result_date"
                style={{
                  fontSize: 32,
                  color: '#005eec',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 'bold',
                }}>
                {getDateForm(resultPopupData.chain_check_time)}
              </p>
              <p
                className="date_subtxt"
                style={{
                  fontSize: 23,
                  color: '#505050',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 400,
                }}>
                &#40;최종 변조 확인시간&#41;
              </p>
              <div className="result_box clearboth">
                <p className="result_label">데이터 저장 블럭수</p>
                <p className="result_data">
                  <span
                    className="data_number"
                    style={{
                      fontSize: 70,
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 500,
                      color: '#005eec',
                    }}>
                    {resultPopupData.blockCnt}
                  </span>
                  <span
                    style={{
                      fontSize: 30,
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 400,
                      color: '#191919',
                    }}>
                    건
                  </span>
                </p>
              </div>
              <div className="result_box clearboth">
                <p className="result_label">데이터 트랜젝션 수</p>
                <p className="result_data">
                  <span
                    className="data_number"
                    style={{
                      fontSize: 70,
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 500,
                      color: '#005eec',
                    }}>
                    {resultPopupData.trajectionCnt}
                  </span>
                  <span
                    style={{
                      fontSize: 30,
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 400,
                      color: '#191919',
                    }}>
                    건
                  </span>
                </p>
              </div>
              <div className="result_box clearboth" style={{ borderBottom: 'none' }}>
                <p className="result_label">위변조 수 </p>
                <p className="result_data">
                  <span
                    className="data_number color_red"
                    style={{
                      fontSize: 70,
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 500,
                      color: '#e62b00',
                    }}>
                    {resultPopupData.frgeryCnt}
                  </span>
                  <span
                    style={{
                      fontSize: 30,
                      fontFamily: 'Noto Sans KR',
                      fontWeight: 400,
                      color: '#191919',
                    }}>
                    건
                  </span>
                </p>
              </div>
            </div>
            <a
              onClick={(event) => {
                event.preventDefault();
                setPopupOn(false);
              }}
              href="/"
              className="popup_close">
              닫기
            </a>
          </section>
        )}
      </>
      {/* )} */}
    </div>
  );
}
