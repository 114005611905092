import React from "react";

export default function UserNotice({ }) {
    return (
        <div
            style={{
                height: "calc(100% - 96px)",
                width: "100%",
                position: "fixed",
                zIndex: 1000,
                backgroundColor: "#ffffff",
                overflow: "auto",
                top: 96,
            }}
        >
            <div id="cont_area">
                <div className="cont_tit2">
                    <h1>
                        부산블록체인규제자유특구
                        <br />
                        실증특례 이용자 고지
                    </h1>
                </div>
                <div
                    className="contents"
                    style={{
                        borderBottomWidth: 0,
                        marginTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0
                    }}>
                    <p className="point_txt">
                        부산블록체인규제자유특구 실증특례 이용자 고지
                        <br />
                        블록체인 기반 스마트 해양물류 플랫폼 서비스
                        <br />
                        <br />
                    </p>
                    <p className="weight_bold">
                        &#40;주&#41;비피앤솔루션 &#40;재&#41;부산테크노파크
                        <br />
                        &#40;주&#41;퀘스트엑스 &#40;주&#41;마린플랫 &#40;주&#41;이에스피
                        <br />
                        <br />
                    </p>
                    <p>
                        <ul>
                            <li>
                                <span className="weight_bold">1. 규제특례 구역, 기간, 규모</span>
                                <ol>
                                    <li>
                                        ㅇ실증특례 구역 &#58; 부산 블록체인 규제자유특구
                                        <ol>
                                            <li>
                                                ※중소벤처기업부 고시 제2019&#45;41호, 80호,
                                                제2019&#45;54호, 58호
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        ㅇ실증특례 기간 &#58; 2019.08.09. &#126; 2021.08.08.
                                        &#40;2년&#41;
                                        <ol>
                                            <li>
                                                ※실증기간 &#58; &#40;1차&#41;
                                                &#39;20.09.17&#126;&#39;20.12.31, &#40;2차&#41;
                                                &#39;21.01.01&#126; &#39;21.3.31,
                                                <br />
                                                &#40;3차&#41; &#39;21.04.01&#126;&#39;21.08.08
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        ㅇ실증규모 &#40;1차&#41; 7개사, &#40;2차&#41; 20개사,
                                        &#40;3차&#41; 40개사
                                        <br />
                                        <br />
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="weight_bold">
                                    2. 법 제86조의 제4항의 안정성 등 확보 조건
                                </span>
                                <ol>
                                    <li>
                                        ㅇ스마트 콜드체인용 영업용 화물차량은 특구사업자가 직접
                                        소유하고 사업기간동안 5대 이하의 차량만 등록
                                    </li>
                                    <li>
                                        ㅇ개인정보와 개인위치정보의 원본정보는 서버에 저장하고
                                        맵핑정보만 <br />
                                        블록체인에 저장하는 오프체인 저장방식을 적용
                                        <ol>
                                            <li>
                                                ※파기의무 발생 시 서버에 저장된 원본정보를 삭제
                                                <br />
                                                <br />
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="weight_bold">3. 책임보험 또는 손해 배상방안 내용</span>
                                <ol>
                                    <li>
                                        ㅇ책임보험 : 대인 1.8억원, 대물 10억원
                                        <ol>
                                            <li>
                                                ※지역특구법 시행령 제60조&#40;책임보험 등 가입&#41;
                                                근거하여 보상 실시
                                                <br />
                                                <br />
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="weight_bold">
                                    4. 기타 장관이 제품·서비스 이용과 관련하여 지정한 사항
                                </span>
                                <ol>
                                    <li>
                                        ㅇ해당사항 없음
                                        <br />
                                        <br />
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="weight_bold">5. 관련근거</span>
                                <ol>
                                    <li>
                                        ㅇ규제자유특구 및 지역특화발전특구에 관한 특례법&#40;약칭
                                        &#58; 지역특구법&#41;
                                    </li>
                                    <li>
                                        ㅇ규제자유특구 지정&#40;중소벤처기업부 고시 제2019&#45;41호,
                                        제2019&#45;80호&#41;
                                    </li>
                                    <li>
                                        ㅇ규제자유특구 지정&#40;중소벤처기업부 고시 제2020&#45;54호,
                                        제2020&#45;58호&#41;
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    )
}
