import React, { useEffect, useState } from "react";

const menus = [
    "서비스 흐름도",
    "주요 데이터의 산출방식",
    "실증시설 및 장비",
    "수출지원 플랫폼 소개",
    "기대효과",
    "B fresh 가 바꾸는 미래!",
    "실증 참여기업 소개"
]

export default function BusinessIntroduction() {
    const [selMenuIdx, setSelMenuIdx] = useState(false)
    const [opacity1, setOpacity1] = useState(0)
    const [opacity2, setOpacity2] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity1(1)
            setTimeout(() => {
                setOpacity2(1)
            }, 700);
        }, 300);
    }, [])

    return (
        <div style={{ height: window.innerHeight }}>
            <div
                id="businessBody"
                style={{
                    height: "calc(100% - 96px)",
                    width: "100%",
                    position: "relative",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: 720,
                    zIndex: 100,
                    backgroundColor: "#ffffff",
                    top: 96

                }}>
                <div style={{
                    paddingTop: 61,
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#ffffff",
                    zIndex: 100,
                    zIndex: 100,
                    position: "relative"
                }}>
                </div>
                <img
                    style={{
                        opacity: opacity1,
                        top: -1,
                        transition: "1.5s",
                        backgroundColor: "#ffffff",
                        height: 980,
                        zIndex: 101, position: "relative",
                    }}
                    src={require("../images/info_main_bg.png")} />
                <div style={{
                    position: "absolute",
                    backgroundColor: "#ffffff",
                    zIndex: 100,
                    height: 975,
                    top: 60,
                    width: 720,
                }} />
                <div
                    style={{
                        position: "absolute",
                        top: 100,
                        height: 900,
                        width: 720,
                        left: 0,
                        paddingTop: 100,
                        textAlign: "center",
                        paddingBottom: 100, backgroundColor: "#ffffff",
                    }}>
                    <img
                        style={{
                            marginTop: 0, zIndex: 103, position: "relative",
                            opacity: opacity2,
                            transition: "1s",
                            top: opacity2 === 1 ? 0 : 300
                        }}
                        src={require("../images/info_main_tit.png")} />
                    <div style={{
                        zIndex: 103, textAlign: "center", position: "relative",
                        opacity: opacity2,
                        transition: "1s",
                        top: opacity2 === 1 ? 0 : 300
                    }}>
                        <img
                            style={{ marginTop: 40, marginBottom: 40, zIndex: 103, position: "relative" }}
                            src={require("../images/info_deco.png")} />
                    </div>
                    <div style={{
                        opacity: opacity2,
                        transition: "1s",
                        top: opacity2 === 1 ? 0 : 300,
                        textAlign: "center", zIndex: 103, position: "relative"
                    }}>
                        <span
                            style={{
                                fontFamily: "Noto Sans KR",
                                fontWeight: "300",
                                lineHeight: "45px",
                                fontSize: 26,
                                zIndex: 100,
                                position: "relative",
                                color: "#ffffff",
                            }}>
                            2019년 중소벤처기업부가 추진한 규제자유특구 지정<br />
                        사업에서 부산광역시가 "블록체인" 으로<br />
                        규제자유특구로 선정되었습니다.<br />(주)비피앤솔류션과 (재)부산테크노파크가 블록체인 관련<br />
                        4개 세부 과제 중 물류분야인<br />
                        "블록체인 기반 스마트 해양물류 플랫폼 서비스"로<br />
                        실증사업을 수행하게 되었으며,<br />
                            <span style={{ fontWeight: "bold" }}>
                                "B fresh (비프레쉬)"
                        </span>
                        는 해당 서비스의 브랜드명입니다.
                    </span>
                    </div>
                </div>
                <div style={{
                    marginBottom: 98,
                }}>
                    {menus.map((item, i) => {
                        return (
                            <div style={{
                                backgroundColor: "#ffffff",
                            }} key={i}>
                                <div
                                    id={"menuItem" + i}
                                    onClick={() => {
                                        if (i === selMenuIdx) setSelMenuIdx(false)
                                        else {
                                            setSelMenuIdx(i)
                                        }
                                    }}
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "500px 220px",
                                        paddingLeft: 32,
                                        backgroundColor: "#ffffff",
                                        height: 98,
                                        width: 720,
                                        borderTop: "2px solid #e0e0e0",
                                        lineHeight: "98px",
                                        borderBottom: i === selMenuIdx ? "2px solid #e0e0e0" : "none",
                                        fontSize: 27,
                                        color: "#505050",
                                        fontFamily: "Noto Sans KR",
                                        fontWeight: "bold",
                                        position: "sticky",
                                        top: -2,
                                        zIndex: 10,
                                    }}>
                                    <span >{item}</span>
                                    <div
                                        style={{
                                            display: "inline-block",
                                            backgroundColor: "#ffffff",
                                            height: 98,
                                        }}>
                                        <img
                                            style={{
                                                padding: 10,
                                                marginTop: 34,
                                                marginLeft: 120,
                                                transformOrigin: "50% 50%",
                                                transition: "1.2s",
                                                transform: `
                                                rotate(${(i === selMenuIdx ? -180 : 0)}deg)
                                            `,
                                            }}
                                            src={require("../images/icon_more.png")} />
                                    </div>
                                </div>
                                { getContent(i, selMenuIdx === i)}
                            </div>
                        )
                    })}

                </div>
            </div >
        </div >
    )
}


const getContent = (idx, isShowContent) => {
    switch (idx) {
        case 0:
            return <Menu_1 isShowContent={isShowContent} />
        case 1:
            return <Menu_2 isShowContent={isShowContent} />
        case 2:
            return <Menu_3 isShowContent={isShowContent} />
        case 3:
            return <Menu_4 isShowContent={isShowContent} />
        case 4:
            return <Menu_5 isShowContent={isShowContent} />
        case 5:
            return <Menu_6 isShowContent={isShowContent} />
        case 6:
            return <Menu_7 isShowContent={isShowContent} />
    }
}

const Menu_1 = ({ isShowContent }) => {
    useEffect(() => {
        if (isShowContent) {
            const businessBody = document.getElementById("businessBody")
            businessBody.scrollTo({ top: 1043, behavior: "smooth" });
        }
    }, [isShowContent])

    return (
        <>
            <div style={{
                height: isShowContent ? 1450 : 0,
                backgroundColor: "#ffffff"
            }} />
            <div
                style={{
                    paddingTop: 50,
                    paddingLeft: 32,
                    transition: isShowContent ? "1.2s" : "0s",
                    position: "absolute",
                    top: isShowContent ? 1130 : -400,
                    zIndex: isShowContent ? 1 : 0,
                    backgroundColor: "#ffffff"
                }}>
                <img src={require("../images/menu1_cont_01.png")} />
                <div style={{ height: 50, }}> <img style={{ marginTop: 10.5, marginLeft: 88, }} src={require("../images/menu1_cont_line.png")} /></div>
                <img src={require("../images/menu1_cont_02.png")} />
                <div style={{ height: 50, }}> <img style={{ marginTop: 10.5, marginLeft: 88, }} src={require("../images/menu1_cont_line.png")} /></div>
                <img src={require("../images/menu1_cont_03.png")} />
                <div style={{ height: 50, }}> <img style={{ marginTop: 10.5, marginLeft: 88, }} src={require("../images/menu1_cont_line.png")} /></div>
                <img src={require("../images/menu1_cont_04.png")} />
                <div style={{ height: 50, }}> <img style={{ marginTop: 10.5, marginLeft: 88, }} src={require("../images/menu1_cont_line.png")} /></div>
                <img src={require("../images/menu1_cont_05.png")} />
                <div style={{ height: 50, }}> <img style={{ marginTop: 10.5, marginLeft: 88, }} src={require("../images/menu1_cont_line.png")} /></div>
                <img style={{ marginBottom: 80 }} src={require("../images/menu1_cont_06.png")} />
            </div>
        </>
    )
}

const Menu_2 = ({ isShowContent }) => {
    useEffect(() => {
        if (isShowContent) {
            const businessBody = document.getElementById("businessBody")
            businessBody.scrollTo({ top: 1143, behavior: "smooth" });
        }
    }, [isShowContent])

    return (
        <>
            <div style={{
                height: isShowContent ? 4000 : 0,
                backgroundColor: "#ffffff"
            }} />
            <div
                style={{
                    paddingTop: 50,
                    paddingLeft: 32,
                    transition: isShowContent ? "1.2s" : "0s",
                    position: "absolute",
                    top: isShowContent ? 1230 : -3000,
                    zIndex: isShowContent ? 1 : 0,
                    backgroundColor: "#ffffff"
                }}
                className="cont_area bg_gray">
                <div className="cont_tit123" >
                    <h1>물류신선도 기준</h1>
                </div>
                <div className="contents123">
                    <p className="point_txt123">제품의 유통.물류 총 시간에서 비신선 환경 노출 비중에 가중치를 반영하여 산정</p>
                    <br />
                    <p>
                        <span className="weight_bold">&#91;최고신선&#93;</span> 물류신선도 95&#37; 이상<br />
                        <span className="weight_bold">&#91;매우신선&#93;</span> 물류신선도 90&#37; &#126; 94.99&#37;<br />
                        <span className="weight_bold">&#91;보통신선&#93;</span> 물류신선도 80&#37; &#126; 89.99&#37;<br />
                        <span className="weight_bold">&#91;보통&#93;</span> 물류신선도 70&#37; &#126; 79.99&#37;<br />
                        <span className="weight_bold">&#91;신선기준 이하&#93;</span> 물류신선도 70&#37; 미만
                </p>
                    <br />
                    <div>
                        <ul>
                            <li><span className="weight_bold">※ 물류신선도 산정 기준</span>
                                <ol>
                                    <li>&#45; 신선제품의 유통.물류 총 시간을 단위시간&#40;10분&#41;으로 나누어 단위물류신선도 산정</li>
                                    <li>&#45; 각 단위물류신선도에 해당 시간별 산정가중치 적용</li>
                                    <li>&#45; 수산전문인&#40;부경대학교&#41;의 자문의견 반영</li>
                                    <li>&#45; &#40;예정&#41; 축적된 유통/물류 빅데이터를 활용하여 물류신선도 반영</li>
                                </ol>
                            </li>
                            <br />
                            <li><span className="weight_bold">※ 물류신선도 산정 예시<br />&#91;물류신선도 산정예시 조건&#93;</span>
                                <ol>
                                    <li>&#45; 물류신선도는 본 플랫폼 서비스에서 신선물류 산정에 최초로 적용하는 개념으로, 36시간을 신선물류&#40;수산물&#41; 한계시간으로 기준하고, 그 시간을
                                10분단위로 216구간으로 나누어 각 구간별로 비신선환경 노출시간을 각 단위신선도에 누적 반영한 것임.</li>
                                    <li>&#45; 아래 산정 예시는 양륙한 수산물을 3시간&#40;18구간&#41; 동안 이송하는 동안에 적용되는 사례를 현 산정방법에 따라 계산한 것임.</li>
                                </ol>
                            </li>
                            <br />
                        </ul>
                        <table className="table_fresh">
                            <tr>
                                <th colSpan="4" style={{ textAlign: "left" }}>&#42;&#42; 물류신선도 산정식&#40;물류신선가중치 합산방식&#41;</th>
                            </tr>
                            <tr className="table_th">
                                <th>단위시간&#40;10분&#41;</th>
                                <th>단위신선기준치</th>
                                <th>비신선노출시간</th>
                                <th>단위신선도</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>99.861</td>
                                <td>10.00</td>
                                <td>99.7897</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>99.583</td>
                                <td>0.00</td>
                                <td>99.5833</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>99.583</td>
                                <td>0.00</td>
                                <td>99.5833</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>99.444</td>
                                <td>0.00</td>
                                <td>99.4444</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>99.306</td>
                                <td>0.00</td>
                                <td>99.3056</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>99.167</td>
                                <td>0.00</td>
                                <td>99.1667</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>99.028</td>
                                <td>0.00</td>
                                <td>99.0278</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>98.889</td>
                                <td>0.00</td>
                                <td>98.8889</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>98.750</td>
                                <td>10.00</td>
                                <td>98.1071</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>98.611</td>
                                <td>0.00</td>
                                <td>98.6111</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>98.472</td>
                                <td>0.00</td>
                                <td>98.4722</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>98.333</td>
                                <td>0.00</td>
                                <td>98.3333</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>98.194</td>
                                <td>0.00</td>
                                <td>98.1944</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>98.056</td>
                                <td>0.00</td>
                                <td>98.0555</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>97.917</td>
                                <td>0.00</td>
                                <td>97.9167</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>97.778</td>
                                <td>0.00</td>
                                <td>97.7778</td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>97.639</td>
                                <td>0.00</td>
                                <td>97.6389</td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>97.500</td>
                                <td>10.00</td>
                                <td>96.2143</td>
                            </tr>
                            <tr style={{
                                borderTop: "1px solid #bdbdbd"
                            }}>
                                <td colSpan="2"></td>
                                <th>&#42;물류신선도</th>
                                <th>98.562</th>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style={{
                    marginTop: 30,
                    fontSize: 24,
                    fontWeight: 300,
                    lineHeight: "43px",
                    color: "#505050",
                }}> ※ 물류신선도는 현재 정립되어 있지 않은 새로운 개념이지만,
                    해당 분야의 전문 연구진 및 학계와의 연구협업, 자문검토를 통해서 보다 객관적이고
                합리적인 기준의 물류신선도 측정 방법으로 개선해 나갈 예정입니다.</div>

                <br /><br /><br /><br /><br /><br />
                <div className="cont_tit123">
                    <h1>비상시 방사능 오염 측정 기준 적용</h1>
                </div>
                <div className="contents123" style={{ paddingBottom: 30 }}>
                    <p>
                        본 실증에서 이루어지는 방사능 오염측정은 산지 수집후 물류이동 과정에서 해당 제품에서 발생 되는 표면방사능 검출 여부를 판단하는 용도에 한정되며 해당 물류 단위에 포함된 제품의 방사능
                        오염 정밀 검사 또는 폐기 등의 후속 조치를 내리기 위한 용도로만 사용되어 집니다. 방사능 오염에 대해 안전하지 않다고 판단된 제품은 원천적으로 최종 소비자에게 전달 될 수가 없기에
                        안전하다는 데이터만 보여 지게 됩니다.
                </p>
                    <br /><br />
                    <p>
                        <ul>
                            <li><span className="weight_bold">&#91;안전&#93; 1μSv/h 미만</span>
                                <ol>
                                    <li>&#45; 피검사 상품의 방사능 오염도 기준 이하</li>
                                    <li>&#45; 상품의 유통 거래 지속</li>
                                </ol>
                            </li>
                            <br />
                            <li><span className="weight_bold">&#91;주의/정밀측정 요망&#93; 1μSv/h~100μSv/h</span>
                                <ol>
                                    <li>&#45; 부산 스마트 콜드체인 상황실에 해당 방사능 오염 상황 즉시통보<br />(자동)</li>
                                    <li>&#45; 상품의 유통 즉시 중단&#40;기준에 따라 폐기 및 반품 처리&#41;</li>
                                    <li>&#45; 접촉자 즉시 연락후 제염 조치&#40;탈의, 세수 및 전신세척 등&#41; 통보 및 재측정 지시, 제염조치 이후에도 방사선량률 측정 수치가 크게 변하지
                                않는 경우 기준에 따라 지정병원 후송 또는 제염 재실시</li>
                                </ol>
                            </li>
                            <br />
                            <li><span className="weight_bold">&#91;위험&#93; 100μSv/h 초과</span>
                                <ol>
                                    <li>&#45; 부산 스마트 콜드체인 상황실에 해당 방사능 오염 상황 즉시통보<br />(자동)</li>
                                    <li>&#45; 상품의 유통 즉시 중단 후 상황 발생 장소에 정지&#40;이동금지&#41;</li>
                                    <li>&#45; 상황실은 즉시 119 및 지정병원에 연락 후 지시에 따라 행동</li>
                                </ol>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    )
}

const Menu_3 = ({ isShowContent }) => {
    useEffect(() => {
        if (isShowContent) {
            const businessBody = document.getElementById("businessBody")
            businessBody.scrollTo({ top: 1243, behavior: "smooth" });
        }
    }, [isShowContent])

    return (
        <>
            <div style={{
                height: isShowContent ? 2250 : 0,
                backgroundColor: "#ffffff"
            }}></div>
            <div
                style={{
                    transition: isShowContent ? "1.2s" : "0s",
                    position: "absolute",
                    top: isShowContent ? 1330 : -1305,
                    zIndex: isShowContent ? 1 : 0,
                    backgroundColor: "#ffffff"
                }}
                className="cont_area bg_gray" >
                <div className="cont_tit2_123">
                    <h1>실증사업 현장</h1>
                </div>
                <div className="contents123">
                    <img src={require("../images/pic_01.png")} className="cont_img" />
                    <p>
                        <span className="weight_bold">소재지</span> : 부산시 사하구 다산로 262 (신평장림일반산업단지 내)<br />
                        <span className="weight_bold">규 모</span> : 약 200평 단층 공장건물<br />
                        <span className="weight_bold">구 성</span> : 스마트 물류시설-약 100평, 스마트 수산물 가공시설-약100평 으로 구성
			        </p>
                </div>
                <br /><br />
                <div className="cont_tit2_123">
                    <h1>실증장비</h1>
                </div>
                <div className="contents123" style={{}}>
                    <img src={require("../images/pic_02.png")} className="cont_img" />
                    <p>
                        <ul >
                            <li ><span className="weight_bold">스마트 콜드체인 화물차량 3대 운용</span>
                                <ol style={{ marginLeft: 10 }}>
                                    <li>&#45; 스마트 냉장 덤프트럭 1대</li>
                                    <li>&#45; 친환경 냉장냉동 탑차 1대</li>
                                    <li>&#45; 친환경 냉장 탑차 1대</li>
                                </ol>
                            </li>
                            <br />
                            <li><span className="weight_bold">스마트 수산물 가공/분류 시설</span>
                                <ol style={{ marginLeft: 10 }}>
                                    <li>&#45; 침지식 상승 리프트 1기</li>
                                    <li>&#45; 컨배어 세척기 1기</li>
                                    <li>&#45; 벨트식 중량 선별기 1기</li>
                                    <li>&#45; 냉장실 1기</li>
                                    <li>&#45; 냉동실 1기</li>
                                    <li>&#45; 스마트 태그 인식기 1기</li>
                                    <li>&#45; 스마트 콜드체인 관제 키오스크 2기</li>
                                </ol>
                            </li>

                        </ul>
                    </p>
                </div>
                <br /><br />
                <div className="cont_tit2_123">
                    <h1>현황 모니터링</h1>
                </div>
                <div className="contents123" style={{ paddingBottom: 40 }}>
                    <img src={require("../images/pic_03.png")} className="cont_img" />
                    <p>
                        <ul >
                            <li >
                                <ol style={{ marginLeft: 10 }}>
                                    <li>&#45; 제품의 온습도 데이터 확인</li>
                                    <li>&#45; 차량 운행 경로 및 이상정보 발생감시.조치</li>
                                </ol>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </>
    )
}

const Menu_4 = ({ isShowContent }) => {
    useEffect(() => {
        if (isShowContent) {
            const businessBody = document.getElementById("businessBody")
            businessBody.scrollTo({ top: 1343, behavior: "smooth" });
        }
    }, [isShowContent])

    return (
        <>
            <div style={{
                width: 720,
                height: isShowContent ? 960 : 0,
                backgroundColor: "#ffffff"
            }} />
            <div style={{
                transition: isShowContent ? "1.2s" : "0s",
                position: "absolute", width: 720,
                top: isShowContent ? 1430 : -888,
                zIndex: isShowContent ? 1 : 0,
            }}
            >
                <div style={{
                    height: 970, width: 720,
                    backgroundImage: `url(${require("../images/export_bg.png")})`,
                    paddingLeft: 32,
                }}>
                    <div
                        style={{
                            paddingTop: 75,
                            fontFamily: "Noto Sans KR",
                            fontSize: 28,
                            fontWeight: "300",
                            lineHeight: "48px",
                            color: "#c4efff"
                        }}>
                        B fresh 서비스를 통한 신선상품이 부산지역 선용품<br />
                    업체들을 통하여 수출로 이어질 때, 외국적 선박으로의<br />
                    수출에 관련된 복잡한 제반 업무를 자동화하여 신속하게<br />
                    수행할 수 있도록 지원하는 서비스 체계입니다.
                </div>
                    <div style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 24,
                        paddingTop: 60,
                        fontWeight: "300",
                        lineHeight: "43px",
                        color: "#ffffff"
                    }}>
                        <span style={{
                            fontWeight: "bold",
                        }}>※ 주요기능</span><br />
                        &nbsp;&nbsp;&nbsp;- 관세청 신고업무 자동화<br />
                        &nbsp;&nbsp;&nbsp;- 실시간 수발주 업무지원 서비스<br />
                        &nbsp;&nbsp;&nbsp;- 수출입 결재 통합관리 서비스<br />
                    </div>
                </div>
            </div>
        </>
    )
}

const Menu_5 = ({ isShowContent }) => {
    useEffect(() => {
        if (isShowContent) {
            const businessBody = document.getElementById("businessBody")
            businessBody.scrollTo({ top: 1443, behavior: "smooth" });
        }
    }, [isShowContent])

    return (
        <>
            <div style={{
                height: isShowContent ? 960 : 0,
                backgroundColor: "#ffffff"
            }} />
            <div style={{
                transition: isShowContent ? "1.2s" : "0s",
                position: "absolute",
                top: isShowContent ? 1530 : -900,
                zIndex: isShowContent ? 1 : 0,
            }}>
                <div style={{
                    paddingLeft: 32,
                    paddingRight: 32,
                    marginTop: 50,
                    marginBottom: 80
                }}>
                    <span style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 28,
                        fontWeight: "bold",
                        lineHeight: "43px",
                        color: "#005eec",
                    }}>
                        스마트 콜드체인 플랫폼 서비스
                </span>
                    <div style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 24,
                        lineHeight: "43px",
                        color: "#505050",
                        fontWeight: 300,
                        marginLeft: 10,
                    }}>
                        - 신선물류 사업자와 소비자를 위한 미래형 유통 · 물류 체계<br />
                    - 오프라인 물류체계의 새로운 도전과 혁신<br />
                    - 불법거래 예방과 물류비용 및 에너지 절감을 위한 혁신<br /><br />
                    </div>
                    <span style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 28,
                        fontWeight: "bold",
                        lineHeight: "43px",
                        color: "#005eec",
                    }}>
                        블록체인 기반 콜드체인 거래 서비스
                </span>
                    <div style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 24,
                        lineHeight: "43px",
                        color: "#505050",
                        fontWeight: 300,
                        marginLeft: 10,
                    }}>
                        - 거래 당사자에 의한 독립적/주도적인 고신뢰 거래 체계 실현<br />
                    - 개인정보 및 거래 정보의 높은 보안 수준 확보<br />
                    - 빅데이터와 인공지능 기술의 접목을 통한 시장 경쟁력 확보<br /><br />
                    </div>
                    <span style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 28,
                        fontWeight: "bold",
                        lineHeight: "43px",
                        color: "#005eec",
                    }}>
                        IoT 콜드체인 장비 및 시설
                </span>
                    <div style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 24,
                        lineHeight: "43px",
                        color: "#505050",
                        fontWeight: 300,
                        marginLeft: 10,
                    }}>
                        - 콜드체인 정보(온도, 습도, 위치, 사고여부, 이상거래 등)<br />
                    - 스마트 콜드체인 전용 차량 및 시설의 고부가가치 상품화 실현<br />
                    - 오프라인 신선물류 및 유통 사업자의 새로운 시장 경쟁력 제공<br /><br />
                    </div>
                    <span style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 28,
                        fontWeight: "bold",
                        lineHeight: "43px",
                        color: "#005eec",
                    }}>
                        스마트 콜드체인 수출지원 서비스
                </span>
                    <div style={{
                        fontFamily: "Noto Sans KR",
                        fontSize: 24,
                        lineHeight: "43px",
                        color: "#505050",
                        fontWeight: 300,
                        marginLeft: 10,

                    }}>
                        - 신선물류의 편리하고 신속한 수출업무 처리 지원<br />
                    - 국제 무역항으로서의 부산항의 위상 증대<br />
                    - 선용품 공급사의 신선물류 서비스 부가가치 증대<br />
                    </div>
                </div>
            </div >
        </>
    )
}

const Menu_6 = ({ isShowContent }) => {
    useEffect(() => {
        if (isShowContent) {
            const businessBody = document.getElementById("businessBody")
            businessBody.scrollTo({ top: 1543, behavior: "smooth" });
        }
    }, [isShowContent])

    return (
        <>
            <div style={{
                height: isShowContent ? 1200 : 0,
                backgroundColor: "#ffffff"
            }} />
            <div style={{
                transition: isShowContent ? "1.2s" : "0s",
                position: "absolute",
                top: isShowContent ? 1630 : -900,
                zIndex: isShowContent ? 1 : 0,
            }}>
                <div style={{ marginTop: 50, }}>
                    <img style={{ marginLeft: 32 }} src={require("../images/future_01.png")} />
                    <img style={{ marginLeft: 32, marginTop: 65, }} src={require("../images/future_02.png")} />
                    <div style={{ marginTop: 100 }}><div style={{
                        paddingLeft: 32, fontFamily: "Noto Sans KR", fontSize: 23, lineHeight: "43px", color: "#505050", fontWeight: 300,
                        paddingBottom: 80,
                        backgroundImage: `url(${require("../images/future_bg.png")})`,
                        backgroundSize: "100% 100%",
                        paddingRight: 32,
                    }}>
                        <span style={{ fontWeight: "bold", color: "#00b0ec" }}>'B Fresh'</span>
                    를 통해서 유통되는 모든 수산물은 생산지에서<br />
                    소매점까지 유통되는 과정의 모든 정보를 블록체인에 기록하여<br />
                    소비자가 직접 확인하여, 가장 신선한 상품을 믿고 선택할 수<br />있습니다.<br /><br />
                        <span style={{ fontWeight: "bold", color: "#00b0ec" }}>'B Fresh'</span>
                    를 활용하는 지역 중소 · 유통 물류 사업자는 대형<br />유통기업들과 경쟁할 수 있는 역량을 가질 수 있습니다.<br /><br />
                    블록체인과 IoT 기술로 대변되는 <span style={{ fontWeight: "bold", color: "#00b0ec" }}>'B Fresh'</span>
                    는 글로벌 경쟁력을 가진 미래 물류 서비스 기술로서 지역 전통산업과 첨단벤처산업이 협력하여 세계로 나아가는 K-ICT(한국형 정보기술수출산업)의<br />
                    견인차로서 자리매김 할 것입니다.
                </div></div>
                </div>
            </div>
        </>
    )
}

const Menu_7 = ({ isShowContent }) => {
    useEffect(() => {
        if (isShowContent) {
            const businessBody = document.getElementById("businessBody")
            businessBody.scrollTo({ top: 1643, behavior: "smooth" });
        }
    }, [isShowContent])

    return (
        <>
            <div style={{
                height: isShowContent ? 2920 : 0,
                backgroundColor: "#ffffff"
            }} />
            <div style={{
                transition: isShowContent ? "1.2s" : "0s",
                position: "absolute",
                width: 720,
                top: isShowContent ? 1800 : -1800,
                zIndex: isShowContent ? 1 : 0,
                marginBottom: 100
            }}>
                <div className="cont_area">
                    <div className="company">
                        <p className="company_img">
                            <img src={require("../images/info_logo_bpns.png")} alt="비피앤솔루션" />
                        </p>
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                &#40;주&#41;비피앤솔루션
                        </span><br />
                        규제자유특구사업자/주관기관</p>
                        <p className="company_info">
                            &#45; IoT/SI/블록체인 서비스 개발 전문기업<br />
                        &#45; B fresh&#40;블록체인 스마트 물류서비스&#41; 사업 총괄.구축.운영
                    </p>
                    </div>
                    <div className="company">
                        <p className="company_img">
                            <img src={require("../images/info_logo_btp.png")} alt="부산테크노파크" />
                        </p>
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                (재)부산테크노파크
                        </span><br />
                        규제자유특구사업자/참여기관</p>
                        <p className="company_info">
                            - 해양물류/수출지원서비스 전문기관<br />
                        - B fresh 수출지원시스템 설계.구축
                    </p>
                    </div>
                    <div className="company">
                        <p className="company_img">
                            <img src={require("../images/info_logo_esp.png")} alt="이에스피" />
                        </p>
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                (주)이에스피
                        </span><br />
                        규제자유특구사업자(신규)/참여기관</p>
                        <p className="company_info">
                            - IoT/장비 개발.공급<br />
                        - B fresh 전용 Iot 장비 개발.설치.유지보수
                    </p>
                    </div>
                    <div className="company">
                        <p className="company_img">
                            <img src={require("../images/info_logo_questx.png")} alt="퀘스트엑스" />
                        </p>
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                (주)퀘스트엑스
                        </span><br />
                        규제자유특구사업자(신규)/참여기관</p>
                        <p className="company_info">
                            - 스마트 수산물 유통.가공기업<br />
                        - B fresh 산지 가공.공급 업무 수행
                    </p>
                    </div>
                    <div className="company">
                        <p className="company_img">
                            <img src={require("../images/info_logo_marineplat.png")} alt="마린플랫" />
                        </p>
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                (주)마린플랫
                        </span><br />
                        규제자유특구사업자(신규)/참여기관</p>
                        <p className="company_info">
                            - 선용품 공급시스템 구축.운영<br />
                        - B fresh 수출지원서비스 전담 운영
                        </p>
                    </div>
                    <div className="company">
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                파도소리(주)
                        </span><br />
                        실증참여사업자</p>
                        <p className="company_info">
                            - 부산시 서구 소재 수산물 가공 전문기업<br />
                        - B fresh 수산물 2차가공 및 유통 업무 수행
                    </p>
                    </div>
                    <div className="company">
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                대청홀푸드
                        </span><br />
                        실증참여사업자</p>
                        <p className="company_info">
                            - 부산시 서구 소재 수산물 유통 전문기업<br />
                        - B fresh 수산물 유통 및 마케팅 업무 수행
                    </p>
                    </div>
                    <div className="company">
                        <p className="company_name">
                            <span
                                className="weight_bold"
                                style={{ fontSize: 28 }}>
                                소비자 유통점
                        </span><br />
                        실증참여사업자</p>
                        <p className="company_info">
                            - 부산시 전역 중소형 판매유통망<br />
                        - B fresh 수산물 판매 업무 수행
                    </p>
                    </div>
                </div>
            </div></>
    )
}