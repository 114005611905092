import React, { useEffect } from "react";

export default function EventPage() {
    const width = window.screen.width
    const height = window.screen.height
    console.log(height)
    return (
        <div
            style={{
                height: "calc(100% - 96px)",
                width: "100%",
                position: "fixed",
                zIndex: 100,
                backgroundColor: "#ffffff",
                overflow: "auto",
                top: 96,
            }}
        >
            <div id="cont_area" >
                <div className="cont_tit2">
                    <h1>비프레쉬 경품 이벤트</h1>
                </div>
                <div
                    className="contents"
                    style={{
                        borderBottomWidth: 0,
                        marginTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0
                    }}>
                    <div>
                        <img style={{ width: "100%" }} src={require("../images/bfresh_event_02.jpg")} />
                        <div onClick={() => {
                            console.log("?")
                            if (window.bfresh === undefined) {
                                try {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "showBrowser", value: "http://naver.me/5meRDgGH" }));
                                } catch (e) {

                                }
                            }
                            else {
                                try { window.bfresh.showBrowser("http://naver.me/5meRDgGH") } catch (e) { }
                            }
                        }} style={{ width: width * 0.31, minWidth: 240, height: 63, position: "absolute", top: 617, left: "33%" }}></div>
                    </div>
                </div>
            </div>
        </div >
    );
}