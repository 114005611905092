/*global kakao*/
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

const KAKAO_API_KEY = "c5c7d2b1c1237f92d90a8bff60439a8c";

export default function TMapMain({ goods, webViewOn, setWebViewOn }) {
  const history = useHistory();
  const maps2 = goods.maps;
  console.log(maps2);
  useEffect(() => {
    // console.log(webViewOn)
    if (!webViewOn) {
      const s2 = document.createElement("script");
      // const maps = [ {
      //     car_div: "BIZ",
      //     car_latitude: "35.078385",
      //     car_longitude: "128.988101",
      //     car_number: "(주)파도소리",
      //     i_serial_no: null,
      // }]

      s2.innerHTML = `
            var map;
            const maps =  JSON.parse('${JSON.stringify(maps2)}');
            var marker, label;
            $(document).ready(function () {
                $(window).resize(function () {
                    // window 창 너비 변경시 그에 따라 Grid 너비 변경
                });
            }); //end of ready
    
            $(window).load(function () {}); //end of load
    
            // 페이지가 로딩이 된 후 호출하는 함수입니다.
            
    
            // 지도에서 지우기 위한 array
            var polyline = null;
    
            // 선그리기. pathData는 배열이며 요소는 object
            function drawPolyLine2(pathData) {
                //선
                polyline = new Tmapv2.Polyline({
                    path: pathData,
                    strokeColor: "#dd00dd", // 라인 색상
                    strokeWeight: 6, // 라인 두께
                    map: map, // 지도 객체
                });
            }
    
            // 마크 그리기
            function addMarkers3(positionData) {
                positionData.forEach(function (item, index) {
                    label =
                        "<span style='background-color: #46414E; color:white;'>" +
                        item.time +
                        "</span>";
                    marker = new Tmapv2.Marker({
                        position: item.path,
                        map: map,
                        //label : label
                    });
                });
            }
    
            // 중심 리셋
            function resetCenter4(arrayPath) {
                var minLat;
                var maxLat;
                var minLng;
                var maxLng;
    
                arrayPath.forEach(function (item, index) {
                    var lat = item._lat;
                    var lng = item._lng;
    
                    if (index == 0) {
                        minLat = lat;
                        maxLat = lat;
                        minLng = lng;
                        maxLng = lng;
                    } else {
                        minLat = lat < minLat ? lat : minLat;
                        maxLat = lat > maxLat ? lat : maxLat;
                        minLng = lng < minLng ? lng : minLng;
                        maxLng = lng > maxLng ? lng : maxLng;
                    }
                });
    
                if (minLat != undefined) {
                    var avgLat = (minLat + maxLat) / 2;
                    var avgLng = (minLng + maxLng) / 2;
                    map.setCenter(new Tmapv2.LatLng(avgLat, avgLng));
                }
            }
    
            //일자 형식
            function dateFormat5(cellvalue, options, rowObject) {
                if (cellvalue.indexOf("-") == -1) {
                    return cellvalue == ""
                        ? ""
                        : cellvalue.substring(0, 4) +
                              "-" +
                              cellvalue.substring(4, 6) +
                              "-" +
                              cellvalue.substring(6);
                } else {
                    return cellvalue;
                }
            }
    
            //일시 format
            function timeFormat6(cellvalue, options, rowObject) {
                var value = cellvalue;
                value = value.split(":").join("");
                if (value != "") {
                    var valueLength = 6 - value.length;
                    for (var i = 0; i < valueLength; i++) {
                        value += "0";
                    }
                }
                return cellvalue == ""
                    ? ""
                    : value.substring(0, 2) +
                          ":" +
                          value.substring(2, 4) +
                          ":" +
                          value.substring(4, 6) +
                          "." +
                          value.substring(6);
            }
    
            function drawMap7(data) {
                var result = [];
                var markerResult = [];
                if (data.length > 0) {
                    var mapBounds = new Tmapv2.LatLngBounds();
                    data.forEach(function (item, index) {
                        if (
                            item.latitude == null ||
                            item.longitude == "" ||
                            item.longitude == 0 ||
                            item.longitude == null ||
                            item.longitude == "" ||
                            item.longitude == 0
                        )
                            return false;
    
                        var path = new Tmapv2.LatLng(item.latitude, item.longitude);
                        result.push(path);
                        mapBounds.extend(path);
                        if (index % 10 == 0) {
                            var gatherDtm = item.gather_dtm;
                            gatherDtm =
                                gatherDtm.substring(0, 2) +
                                ":" +
                                gatherDtm.substring(2, 4) +
                                ":" +
                                gatherDtm.substring(4, 6);
                            var markerData = {
                                time: gatherDtm,
                                path: path,
                            };
                            markerResult.push(markerData);
                        }
                    });
                    if (result.length > 0) {
                        drawPolyLine2(result);
                        map.fitBounds(mapBounds);
                    }
                    if (markerResult.length > 0) {
                        addMarkers3(markerResult);
                    }
                }
            }
    
            function clearMap8() {
                if (polyline != null) {
                    polyline.setMap();
                    polyline = null;
                }
    
                var markers = map._data.mapMarkerLayer._markers;
                if (markers.length > 0) {
                    markers.forEach(function (item, index) {
                        item.setMap();
                    });
                }
            }
    
            function initTmap23 () {
                
                // 1. 지도 띄우기
                let lat = 0;
                let lng = 0;
                //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
                    
                let carArr = [];
                let buildingArr = [];
    
                var karPos = [];
                var accidentPos = [];
                var bisPos = [];
                for (let i = 0; i < maps.length; i++) {
                    const item = maps[i];
    
                    if (item.car_div === "CAR") {
                        carArr.push(item);
                    } else {
                        buildingArr.push(item);
                    }
                }
    
                
                if(carArr.length !== 0) {
                    let lastKarNumber = carArr[0].car_number;
                    for (let i = 0; i < carArr.length; i++) {
                        const item = carArr[i];
                        if (lastKarNumber !== item.car_number) {
                            lastKarNumber = item.car_number;
                            karPos.push(new Tmapv2.LatLng(item.car_latitude, item.car_longitude));
                            
                        }
                        if (carArr.length - 1 === i) {
                            karPos.push(new Tmapv2.LatLng(item.car_latitude, item.car_longitude));
                            
                        } else {
                            accidentPos.push(
                                new Tmapv2.LatLng(item.car_latitude, item.car_longitude)
                            );
                        }
                    }
                }
    
                for (let i = 0; i < buildingArr.length; i++) {
                    const item = buildingArr[i];
                    bisPos.push(new Tmapv2.LatLng(item.car_latitude, item.car_longitude));
    
                    lat += Number(item.car_latitude);
                    lng += Number(item.car_longitude);
                    
                    
                }
    
                
                var accidentMarkers = [];
                var bizMakers = [];
                var karMakers = [];
    
            
                lat = lat / bisPos.length;
                lng = lng / bisPos.length;
        
    
                       
                map = new Tmapv2.Map("popup_map", {
                    //center: new Tmapv2.LatLng(35.075905,128.955139),
                    center: new Tmapv2.LatLng(lat, lng),
                    width: "100%",
                    height: "730px",
                });
                map._data.options.scrollwheel = false;
                map.setOptions({ draggable: false });
                let firstCar = null;
                carArr.map((item, i) => {
                    let label;
                    firstCar = item.car_number;
                        new Tmapv2.Marker({
                            position: karPos[i], 
                            icon: "${require("../../images/icon_delivery.png")}", 
                            map: map,
                            label: "<span style='background-color: #2361b6; color:white; z-index: 100000; position: relative; bottom: 50px; border-radius: 5px; padding: 0px 5px;'>" +  item.car_number + "</span>"
                        });
                  
                })
                buildingArr.map((item, i) => {
                    label = "<span style='background-color: #46414E; color:white; z-index: 100000; position: relative; bottom: 50px; border-radius: 5px; padding: 0px 5px;'>" +  item.car_number + "</span>";
                    new Tmapv2.Marker({
                    position: bisPos[i], 
                    icon: "${require("../../images/icon_building.png")}", 
                    map: map,
                    label: label,
                })
            });
                accidentPos.map((item, i) => {
                 new Tmapv2.Marker({
                    position: item, 
                    icon: "${require("../../images/10.png")}",
                    map: map
                });
               
           })
                map.setZoom(14);
              
            }
        
            // 마크 그리기
            // function addMarkers(positionData) {
            //     positionData.forEach(function (item, index) {
            //         label =
            //             "<span style='background-color: #46414E; color:white;'>" +
            //             item.time +
            //             "</span>";
            //         marker = new Tmapv2.Marker({
            //             position: item.path,
            //             title: item.time,
            //             map: map,
            //             //label : label
            //         });
            //     });
            // }
    
            initTmap23();
    
           `;

      s2.type = "text/javascript";
      s2.async = "async";
      document.head.appendChild(s2);
      setWebViewOn(true);
    } else {
      // document.removeChild
      // const s = document.createElement("script");
      // s.innerHTML = `initTmap23()`
      // s.type = "text/javascript";
      // s.async = "async";
      // document.head.appendChild(s);
      // setWebViewOn(true)
    }
  }, []);

  return (
    <>
      <div style={{ marginBottom: 60, position: "relative", top: -180 }}>
        <div
          style={{ position: "absolute", height: "100%", marginTop: 20 }}
          className="accident_location"
          id={`popup_map`}
        />
        <div
          style={{
            backgroundColor: "#00000000",
            width: "100%",
            height: 730,
            top: 20,
            position: "relative",
          }}
        ></div>
      </div>
      <div style={{}}>
        <img
          onClick={(event) => {
            history.push("/result/map");
          }}
          style={{
            //  cursor: "pointer",
            position: "relative",
            top: -320,
            left: 555,
          }}
          src={require("../../images/btn_map.png")}
          alt="자세히 보기"
        />
      </div>
    </>
  );
}
