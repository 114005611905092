import React, { useState, useEffect } from "react";
import { XYPlot, YAxis, LineSeries, LineMarkSeries, HorizontalGridLines } from "react-vis";

export default function Graph({

    goods,
}) {
    const [averageHumidity, setAverageHumidity] = useState();
    const [averageTemperature, setAverageTemperature] = useState();
    const graphAnimDataMaxCnt = 24;
    const [graphAnimDataCnt, setGraphAnimDataCnt] = useState(1);

    const getXTimeAxis = (startTime, circulationTotalMinute) => {
        let getXTimeAxisArr = [];
        const _hours = Number(startTime.substr(8, 2));
        const _minutes = Number(startTime.substr(10, 2));
        const _lasthours = Number(circulationTotalMinute.substr(8, 2));
        const _lastminutes = Number(circulationTotalMinute.substr(10, 2));

        // const _hours = Number(startTime.substr(10, 3).replace(/[^0-9]/g, ""));
        // const _minutes = Number(startTime.substr(14, 2).replace(/[^0-9]/g, ""));

        startTime = startTime.substr(0, 12);
        circulationTotalMinute = circulationTotalMinute.substr(0, 12);
        const startTimeObj = new Date(
            Number(startTime.substr(0, 4)),
            Number(startTime.substr(4, 2)),
            Number(startTime.substr(6, 2)),
            Number(startTime.substr(8, 2)),
            Number(startTime.substr(10, 2))
        );
        const circulationTotalMinuteObj = new Date(
            Number(circulationTotalMinute.substr(0, 4)),
            Number(circulationTotalMinute.substr(4, 2)),
            Number(circulationTotalMinute.substr(6, 2)),
            Number(circulationTotalMinute.substr(8, 2)),
            Number(circulationTotalMinute.substr(10, 2))
        );

        // diffstart_1 = new Date(
        //     startTimeObj.getFullYear(),
        //     startTimeObj.getMonth() + 1,
        //     startTimeObj.getDate()
        // );
        // diffDate_2 = new Date(
        //     circulationTotalMinuteObj.getFullYear(),
        //     circulationTotalMinuteObj.getMonth() + 1,
        //     circulationTotalMinuteObj.getDate()
        // );

        var diff = Math.abs(startTimeObj.getTime() - circulationTotalMinuteObj.getTime());
        // diff = Math.ceil(diff / (1000 * 3600 * 24));
        diff = Math.ceil(diff / 60000);

        const startTotalMinutes = startTimeObj.getHours() * 60 + startTimeObj.getMinutes();
        for (let i = 0; i < 7; i++) {
            let totalMinutes;
            if (i === 0) {
                totalMinutes = startTotalMinutes;
            } else if (i === 6) {
                totalMinutes = startTotalMinutes + diff;
            } else {
                totalMinutes = startTotalMinutes + (diff / 6) * i;
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = Math.floor(totalMinutes % 60);
            const hoursStr = hours < 10 ? `0${hours}` : hours;
            const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
            getXTimeAxisArr.push(
                <div className="getXTimeAxisItem" style={{ width: 82 * scale }} key={i}>{`${hoursStr}:${minutesStr}`}</div>
            );
        }
        return getXTimeAxisArr;
    };


    useEffect(() => {

    });

    useEffect(() => {
        let _averageHumidity = 0;
        let _averageTemperature = 0;
        const length = goods.temperatureHumidityInfos.length;
        for (let i = 0; i < length; i++) {
            const item = goods.temperatureHumidityInfos[i];
            _averageHumidity += Number(item.humi);
            _averageTemperature += Number(item.temp);
        }

        _averageHumidity = _averageHumidity / length;
        _averageTemperature = _averageTemperature / length;

        setAverageHumidity(5 * Math.ceil(_averageHumidity / 5));
        setAverageTemperature(5 * Math.ceil(_averageTemperature / 5));
    }, []);

    const scale = window.innerHeight / 1150;

    return (
        <div className="distribution_graph"
            style={{
                zIndex: 1,
                transform: "scale( 1.8 )",
                transformOrigin: "top left",
                marginLeft: 43 * scale,
                position: "relative",
                top: -350
            }}>
            <div >
                <div className="y_axis_right">
                    {averageHumidity !== undefined && (
                        <XYPlot style={{ position: "relative", top: -6.5 }} width={550 * scale} height={213.5}>
                            <YAxis left={(524 * scale) + (scale > 1.1 ? 5 : 0)} hideLine={true} />
                            <LineSeries
                                data={[
                                    {
                                        x: 0,
                                        y: averageHumidity - 25,
                                    },
                                    {
                                        x: 0,
                                        y: averageHumidity + 5,
                                    },
                                ]}
                                fill={"#00000000"}
                                color="#00000000"
                            />
                        </XYPlot>
                    )}
                </div>
                {averageTemperature !== undefined && (
                    <XYPlot width={570 * scale} height={220}>
                        <HorizontalGridLines />
                        <YAxis orientation="left" hideLine={true} />
                        <LineSeries
                            data={[
                                { x: 0, y: averageTemperature - 15 },
                                { x: 0, y: averageTemperature + 15 },
                            ]}
                            color="#00000000"
                        />
                        <LineMarkSeries
                            data={
                                goods.temperatureHumidityInfos.map((item, i) => {
                                    return {
                                        x: i,
                                        y:
                                            Number(item.humi) -
                                            (averageHumidity - averageTemperature) +
                                            10,
                                    };
                                })
                            }
                            size={3.5 * scale}
                            fill="#0079d7"
                            stroke="#0079d7"
                        />
                        <LineMarkSeries
                            data={
                                goods.temperatureHumidityInfos.map((item, i) => {
                                    return { x: i, y: Number(item.temp) };
                                })
                            }
                            size={3.5 * scale}
                            fill="#d32f2f"
                            stroke="#d32f2f"
                        />
                    </XYPlot>
                )}
            </div>
            <div className="distribution_graph_ex" style={{ left: (352 * scale) + (scale > 1.1 ? 20 : 5), }}>
                <span className="temperature" style={{ fontSize: 17 * scale, marginRight: 15, }}>
                    <div className="circle" />
                    온도
                </span>
                <span className="humidity" style={{ fontSize: 17 * scale, marginRight: 15 }}>
                    <div className="circle" />
                    습도
                </span>
                <span className="asd" style={{ fontSize: 17 * scale, }}>
                    <img style={{ width: 22, marginTop: 5 * scale, marginRight: 7 }} src={require("../../images/data_radioactivity.png")}></img>
                    방사능
                </span>
            </div>
            <div className="y_axis_title">
                <div className="temperature">(온도)</div>
                <div className="humidity" style={{ marginLeft: 552 * scale }}>(습도)</div>
            </div>
            <div className="data_accident_area" style={{ zIndex: 10 }}>
                {goods.temperatureHumidityInfos.map((accidentInfo, i) => {
                    const asd = (610 * scale) / goods.temperatureHumidityInfos.length;
                    if (Number(accidentInfo.radi) >= 1) {
                        return (
                            <>{<img
                                key={i}
                                style={{
                                    zIndex: 10,
                                    paddingLeft: 32.5 + (asd * i),
                                    // paddingLeft: 652,
                                    top: 350,
                                    width: 14 * scale,
                                }}
                                src={require("../../images/data_radioactivity.png")}
                                alt="data_accident"
                            />}
                            </>
                        );
                    }

                })}
            </div>
            <div className="time_axis_area" style={{ top: -75, left: -22, width: 600 * scale }}>
                <div className="time_axis" style={{ fontSize: 10, }}>
                    {getXTimeAxis(
                        goods.temperatureHumidityInfos[0].time,
                        goods.temperatureHumidityInfos[goods.temperatureHumidityInfos.length - 1]
                            .time
                    )}
                </div>
            </div>
        </div>
    );
}
