import Axios from 'axios';

export const getData3 = async (i_serial_no) => {
  const getTimeNumber = (timeString) => {
    const hour = Number(timeString.substr(0, 2)) * 60;
    const minute = Number(timeString.substr(3, 2));
    const time = hour + minute;
    return time;
  };

  const getBasicInfo = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app010r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  let basicInfo = await getBasicInfo();
  basicInfo = basicInfo.data.data[0];

  return {
    freshnessPer: Number(basicInfo.send_fresh.split('%')[0]),
    averageTemperature: basicInfo.send_avg_temp,
    averageHumidity: basicInfo.send_avg_humi,
    radioactivityLevel: basicInfo.send_radioactivity,
    circulationTotalMinute: getTimeNumber(basicInfo.send_time),
    name: basicInfo.send_good,
    origin: basicInfo.send_origin,
    startTime: basicInfo.send_start_time,
    endTime: basicInfo.send_end_time,
    freshDiv: basicInfo.send_fresh_div,
  };
};

export const getData4 = async (i_serial_no) => {
  const getTemperatureHumidityInfo = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app020r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  let temperatureHumidityInfo = await getTemperatureHumidityInfo();

  return {
    temperatureHumidityInfos: temperatureHumidityInfo.data.data.map((temperatureHumidity) => {
      return {
        humi: temperatureHumidity.hist_humi,
        temp: temperatureHumidity.hist_temp,
        time: temperatureHumidity.hist_time,
        radi: temperatureHumidity.hist_radi,
      };
    }),
  };
};

export const getData = async (i_serial_no) => {
  const getLogisticsInfo = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app030r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  const getAccidentInfo = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app040r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  const getAccidentInfoMaps = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app060r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  const asd = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app070r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  const asdasdasdsa = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app080r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  // let basicInfo = await getBasicInfo();
  let logisticsInfo = await getLogisticsInfo();
  let accidentInfo = await getAccidentInfo();
  let accidentInfoMaps = await getAccidentInfoMaps();
  // let asdasdasd = await asd();
  let asdwww = await asdasdasdsa();

  // basicInfo = basicInfo.data.data[0];
  //   temperatureHumidityInfo = temperatureHumidityInfo.data.data;
  // temperatureHumidityInfo = asdasd
  logisticsInfo = logisticsInfo.data.data;
  accidentInfo = accidentInfo.data.data;
  accidentInfoMaps = accidentInfoMaps.data.data;
  // temperatureHumidityInfo = [{ humi: "67.7", temp: "28.5", time: "2020090308500000" }];

  let maps_ = [];
  for (let i = 0; i < accidentInfoMaps.length; i++) {
    const item = accidentInfoMaps[i];
    if (item !== null) {
      maps_.push(item);
    }
  }

  return {
    // freshnessPer: Number(basicInfo.send_fresh.split("%")[0]),
    // averageTemperature: basicInfo.send_avg_temp,
    // averageHumidity: basicInfo.send_avg_humi,
    // radioactivityLevel: basicInfo.send_radioactivity,
    // circulationTotalMinute: getTimeNumber(basicInfo.send_time),
    // name: basicInfo.send_good,
    // origin: basicInfo.send_origin,
    // startTime: basicInfo.send_start_time,
    // endTime: basicInfo.send_end_time,
    // freshDiv: basicInfo.send_fresh_div,
    maps: maps_,
    logisticsInfos: logisticsInfo.map((item) => {
      return {
        companyname: item.trans_company,
        type: item.trans_div,
        address: item.trans_address,
        releaseDiv: item.trans_end_div,
        releaseTime: item.trans_end_time,
        wearingDiv: item.trans_start_div,
        wearingTime: item.trans_start_time,
      };
    }),
    accidentInfos: accidentInfo.map((item) => {
      return {
        label: item.accident_div,
        carNumber: item.accident_car_no,
        companyname: item.accident_company,
        address: item.accident_address,
        time: item.accident_time,
        desc: item.accident_context,
        lat: item.accident_latitude,
        lng: item.accident_longitude,
      };
    }),
    transitInfos: asdwww.data.data,
  };
};

export const getData2 = async (i_serial_no) => {
  const getData = () => {
    return Axios({
      url:
        'https://nscc.bpnsolution.com:8081/SCC/app/app050r/getData.do?i_serial_no=' + i_serial_no,
      method: 'GET',
    });
  };

  let checkBlockchainData = await getData();
  checkBlockchainData = checkBlockchainData.data.data[0];

  const date = new Date();

  const fn = (val) => {
    if (val < 10) {
      return '0' + val;
    } else {
      return val;
    }
  };

  return {
    chain_check_time:
      date.getFullYear() +
      '-' +
      fn(date.getMonth() + 1) +
      '-' +
      fn(date.getDate()) +
      'T' +
      fn(date.getHours()) +
      ':' +
      fn(date.getMinutes()) +
      ':' +
      fn(date.getSeconds()),
    dateTime: checkBlockchainData.chain_check_time,
    blockCnt: checkBlockchainData.chain_save_block,
    trajectionCnt: checkBlockchainData.chain_transaction,
    frgeryCnt: checkBlockchainData.chain_modulation,
  };
};
