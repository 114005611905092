import React from "react";
import { useHistory } from "react-router-dom";
import BottomSwipe from "./home/BottomSwipe";

export default function Home({ scan }) {
    const history = useHistory();

    return (
        <>
            <div
                className="contents"
                style={{
                    marginTop: 0,
                    paddingTop: 96,
                    height: "calc(100% - 96px)"
                }}>
                <div
                    style={{
                        textAlign: "center",
                        marginLeft: 32,
                        marginRight: 32,
                        height: "100%"
                    }}>
                    <img
                        src={require("../images/main_tit.png")}
                        style={{
                            marginTop: 70,
                            marginBottom: 53
                        }} />
                    <div
                        style={{
                            fontFamily: "Noto Sans KR",
                            fontWeight: 400,
                            fontSize: 38,
                            marginTop: 3,
                            color: "#1d2a3e",
                        }}
                    >
                        블록체인 기반 미래형 물류 서비스
                    </div>
                    <div
                        style={{
                            marginTop: 41,
                            fontFamily: "Noto Sans KR",
                            fontWeight: 400,
                            fontSize: 27,
                            color: "#aeaeae",
                            position: "fixed",
                            textAlign: "center",
                            width: "100%",
                            zIndex: 100,
                            left: 0,
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr"
                        }}
                    >
                        <div />
                        <div><span
                            onClick={() => {
                                history.push("/business_introduction")
                            }}
                            style={{ zIndex: 10, }}
                        >
                            B fresh 알아보기
                            <img
                                src={require("../images/main_arrow.png")}
                                style={{
                                    marginTop: 11,
                                    marginLeft: 3
                                }}
                            />
                        </span></div>
                    </div>
                    <div
                        onClick={scan}
                        style={{
                            position: "fixed",
                            width: 656,
                            height: 200,
                            zIndex: 3,
                            bottom: 349,
                        }} />
                    <img
                        src={require("../images/main_btn_QR.png")}
                        style={{
                            position: "fixed",
                            width: 668,
                            left: 26,
                            bottom: 335,
                            zIndex: 1,
                        }}
                    />
                    <div
                        style={{
                            display: "grid",
                            gridTemplateRows: "100% auto",
                            position: "relative",
                        }}
                    >
                        <BottomSwipe />
                    </div>
                </div>
            </div>
        </>
    );
}
