import React from 'react';
import { useHistory } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import '../../../node_modules/swiper/css/swiper.css';

const img1 = require(`../../images/main_banner_09.png`);
const img2 = require(`../../images/main_banner_01.png`);
const img3 = require(`../../images/main_banner_02.png`);
const img4 = require(`../../images/main_banner_03.png`);
const img7 = require(`../../images/main_banner_05.png`);

let startDate = new Date('2020', '11', '10', '00', '00');
let curDate = new Date();
let endDate = new Date('2020', '11', '20', '00', '00');
startDate = startDate.getTime();
curDate = curDate.getTime();
endDate = endDate.getTime();

const bool = curDate >= startDate && curDate <= endDate;
const swipeArr = bool ? [0, 1, 2, 3, 4] : [1, 2, 3, 4];
// const swipeArr = [0, 1, 2, 3, 4];

export default function BottomSwipe() {
  const history = useHistory();

  const params = {
    loop: true,
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  const click = (selSwipeIdx) => {
    console.log(selSwipeIdx);
    return;
    switch (selSwipeIdx) {
      case 0:
        if (window.bfresh === undefined) {
          try {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                funcName: 'showBrowser',
                value: 'https://bpass-event.coinplug.com',
              }),
            );
          } catch (e) {}
        } else {
          try {
            window.bfresh.showBrowser('https://bpass-event.coinplug.com');
          } catch (e) {}
        }
        break;
      case 1:
        history.push('/user_notice');
        break;
      case 2:
        history.push('/radioactive_contamination');
        break;
      case 3:
        history.push('/logistics_freshness');
        break;
      case 4:
        break;
      // case 4: history.push("/event_page"); break;
      // case 5:
      //     if (window.bfresh === undefined) {
      //         try { window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "showBrowser", value: "https://bpass.page.link/event" })); } catch (e) { }
      //     }
      //     else {
      //         try { window.bfresh.showBrowser("https://bpass.page.link/event") } catch (e) { }
      //     }
      //     break;
      // default: break;
    }
  };

  return (
    <div
      onClick={(event) => {
        switch (event.target.id) {
          case 'slide___1':
            history.push('/user_notice');
            break;
          case 'slide___2':
            history.push('/radioactive_contamination');
            break;
          case 'slide___3':
            history.push('/logistics_freshness');
            break;
          case 'slide___4':
            if (window.bfresh === undefined) {
              try {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    funcName: 'showBrowser',
                    value: 'https://bpass.page.link/event',
                  }),
                );
              } catch (e) {}
            } else {
              try {
                window.bfresh.showBrowser('https://bpass.page.link/event');
              } catch (e) {}
            }
        }
      }}
      style={{
        width: 656,
        position: 'fixed',
        height: 150,
        bottom: 130,
      }}>
      {/* <Swiper {...params}>
                {[1, 2, 3].map((i, idx) => {
                    let imgg;
                    switch (i) {
                        case 1:
                            imgg = img1;
                            break;
                        case 2:
                            imgg = img2;
                            break;
                        case 3:
                            imgg = img3;
                            break;
                        default:
                            break;
                    }

                    return <img
                        onClick={() => {
                            click(idx)
                        }}
                        key={idx}
                        style={{ width: 656 }}
                        src={imgg} />
                })}
            </Swiper> */}
      <Swiper {...params}>
        {swipeArr.map((i, idx) => {
          let imgg;
          switch (i) {
            case 0:
              imgg = img1;
              break;
            case 1:
              imgg = img2;
              break;
            case 2:
              imgg = img3;
              break;
            case 3:
              imgg = img4;
              break;
            case 4:
              imgg = img7;
              break;
            default:
              break;
          }

          return (
            <img
              id={'slide___' + i}
              onClick={() => {
                // if (bool && i === 4) {
                //     console.log("?")
                //     if (window.bfresh === undefined) {
                //         try {
                //             window.ReactNativeWebView.postMessage(JSON.stringify({ funcName: "showBrowser", value: "https://blockchainbusan.kr/boardview.do?boardnum=165&boardtype=notice" }));
                //         } catch (e) {
                //         }
                //     }
                //     else {
                //         try {
                //             window.bfresh.showBrowser("https://blockchainbusan.kr/boardview.do?boardnum=165&boardtype=notice")
                //         }
                //         catch (e) {
                //         }
                //     }
                // }
                // else {
                //     console.log("?")
                // click(i)
                // }
              }}
              key={idx}
              style={{ width: 656 }}
              src={imgg}
            />
          );
        })}
      </Swiper>
    </div>
  );
}
