import React, { useEffect, useState } from 'react';
import ResultPopup from './result/ResultPopup';
import LogisticsInfo from './result/LogisticsInfo';
import BasicInfo from './result/BasicInfo';
import { getData, getData3, getData4 } from './result/axios';
import TMapMainPopup from './result/TMapMainPopup';
import FloatingButton from './result/FloatingButton';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import LogisticsFreshnessData from './result/LogisticsFreshnessData';
import '../css/Home.css';
import GraphPopup from './result/GraphPopup';

export default function Result({ scanId, webViewOn, popupOn, setPopupOn, setWebViewOn }) {
  const [resultPopupData, setResultPopupData] = useState(null);
  const [goods, setGoods] = useState(null);
  const [loadingImagePos, setLoadingImagePos] = useState(0);
  const [isGetDataSuccess, isSetGetDataSuccess] = useState(false);
  const [isGetDataSuccess3, isSetGetDataSuccess3] = useState(false);
  const [isGetDataSuccess4, isSetGetDataSuccess4] = useState(false);

  const GetData = async () => {
    try {
      const data = await getData(scanId);
      setGoods({ ...goods, ...data });
    } catch (err) {
      // console.log(err)
    }
    isSetGetDataSuccess(true);
  };

  const GetData3 = async () => {
    try {
      const data = await getData3(scanId);
      setGoods({ ...goods, ...data });
    } catch (err) {
      // console.log(err)
    }
    isSetGetDataSuccess3(true);
  };

  const GetData4 = async () => {
    try {
      const data = await getData4(scanId);
      setGoods({ ...goods, ...data });
    } catch (err) {
      // console.log(err)
    }
    isSetGetDataSuccess4(true);
  };

  useEffect(() => {
    if (!isGetDataSuccess) GetData();
    else {
      if (!isGetDataSuccess3) GetData3();
      else if (!isGetDataSuccess4) GetData4();
    }
  }, [isGetDataSuccess, isGetDataSuccess3, isGetDataSuccess4]);

  return (
    <>
      {goods !== null && (
        <>
          <>
            <div
              className="contents"
              style={{
                marginTop: 90,
                paddingTop: 40,
                paddingLeft: 33.5,
                paddingRight: 33.5,
                background: 'none',
              }}>
              <BasicInfo
                data={goods}
                setPopupOn={setPopupOn}
                isGetDataSuccess4={isGetDataSuccess4}
                setLoadingImagePos={setLoadingImagePos}
                setResultPopupData={setResultPopupData}
              />
              <LogisticsInfo data={goods} webViewOn={webViewOn} setWebViewOn={setWebViewOn} />
            </div>
            {popupOn === 'result' && (
              <ResultPopup
                loadingImagePos={loadingImagePos}
                setLoadingImagePos={setLoadingImagePos}
                resultPopupData={resultPopupData}
                setResultPopupData={setResultPopupData}
                scanId={scanId}
                setPopupOn={setPopupOn}
              />
            )}
            {popupOn === 'graph' && <GraphPopup setPopupOn={setPopupOn} goods={goods} />}
          </>
          {!popupOn && (
            <FloatingButton isGetDataSuccess4={isGetDataSuccess4} setPopupOn={setPopupOn} />
          )}
          <Switch>
            {isGetDataSuccess4 && (
              <Route exact path="/result/logistics_freshness_data">
                <LogisticsFreshnessData goods={goods} />
              </Route>
            )}
            <Route exact path="/result/map">
              <TMapMainPopup goods={goods} />
            </Route>
          </Switch>
        </>
      )}
    </>
  );
}

// setGoods({
//     freshnessPer: 90.5,
//     averageTemperature: 2,
//     averageHumidity: 70,
//     radioactivityLevel: 0.01,
//     circulationTotalMinute: 230,
//     name: "고등어소고 (500g)",
//     origin: "부산 / 공동어시장 / 대형선망선단 / 담당자(윤채원)",
//     startTime: "2020. 04. 09. 06 : 30",
//     endTime: "2020. 04. 09. 06 : 30",
//     logisticsInfos: [
//         {
//             type: "circulation",
//             address: "사하구 장림동",
//             companyname: "(주)Quest-X",
//             wearingTime: "2020. 04. 09. 06 : 30",
//             releaseTime: "2020. 04. 09. 08 : 15",
//         },
//         {
//             type: "logistics",
//             address: "사하구 장림동",
//             companyname: "(주)비피앤솔루션",
//             wearingTime: "2020. 04. 09. 08 : 17",
//             releaseTime: "2020. 04. 09. 08 : 23",
//         },
//         {
//             type: "circulation",
//             address: "사하구 구동",
//             companyname: "(주)파도소리",
//             wearingTime: "2020. 04. 09. 0 : 25",
//             releaseTime: "2020. 04. 09. 09 : 08",
//         },
//         {
//             type: "logistics",
//             address: "사하구 장림동",
//             companyname: "(주)비피앤솔루션",
//             wearingTime: "2020. 04. 09. 09 : 10",
//             releaseTime: "2020. 04. 09. 09 : 20",
//         },
//         {
//             type: "circulation",
//             address: "사하구 장림동",
//             companyname: "(주)서원유통",
//             wearingTime: "2020. 04. 09. 09 : 30",
//             releaseTime: null,
//         },
//     ],
//     accidentInfos: [
//         {
//             label: "정차",
//             carNumber: "부산 88-가 8888",
//             companyname: "(주)영신화물",
//             address: "부산시 서구 충무동 충무교차로 근처",
//             time: "07:15",
//             desc: "타이어 수리",
//             lat: "37.506502",
//             lng: "127.053617",
//         },
//         {
//             label: "개문",
//             carNumber: "부산 88-가 9999",
//             companyname: "(주)신속물류",
//             address: "부산시 동구 부산역 근처",
//             time: "08:25",
//             desc: "화물 재정리",
//             lat: "37.506502",
//             lng: "127.053617",
//         },
//     ],
// });
