/*global kakao*/
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Graph from './Graph';

export default function GraphPopup({ goods, setPopupOn }) {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      className="body_rotate"
      style={{
        transform: 'rotate(-90deg)',
        transformOrigin: '0 0',
        backgroundColor: 'white',
        width: window.innerHeight,
        height: window.innerWidth,
        position: 'fixed',
        top: '100%',
        left: 0,
        zIndex: 100000,
      }}>
      <div className="header_area" style={{}}>
        <div className="header" style={{}}>
          <h1
            style={{
              height: 95,
            }}>
            <span
              onClick={() => {
                setPopupOn(false);
              }}
              className="icon_back">
              <img
                style={{
                  padding: '13px 19.5px',
                }}
                src={require('../../images/icon_back.png')}
                alt="QR스캔"
                className="QRscan_img"
              />
            </span>
            <img
              src={require('../../images/header_logo.png')}
              alt="QR스캔"
              style={{
                marginTop: 15,
              }}
              className="QRscan_img"
            />
            <div
              style={{
                backgroundColor: '#eeeeee',
                width: '100%',
                height: '1px',
                position: 'relative',
                top: -30,
              }}></div>
          </h1>
        </div>
      </div>
      <div style={{ marginTop: 200 }}>
        {goods.temperatureHumidityInfos && goods.temperatureHumidityInfos.length !== 0 && (
          <Graph goods={goods} />
        )}
      </div>
    </div>
  );
}
