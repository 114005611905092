import React, { useEffect } from "react";

export default function EventResult() {
    const width = window.screen.width
    const height = window.screen.height
    console.log(height)
    return (
        <div
            style={{
                height: "calc(100% - 96px)",
                width: "100%",
                position: "fixed",
                zIndex: 100,
                backgroundColor: "#ffffff",
                overflow: "auto",
                top: 96,
            }}
        >
            <div id="cont_area" >
                <div className="cont_tit2">
                    <h1>비프레쉬 경품이벤트 당첨자 발표</h1>
                </div>
                <div
                    className="contents"
                    style={{
                        borderBottomWidth: 0,
                        marginTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0
                    }}>
                    <div>
                        <img style={{ width: "100%" }} src={require("../images/event_result.jpg")} />
                    </div>
                </div>
            </div>
        </div >
    );
}